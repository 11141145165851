import React, { useState, lazy, Suspense } from "react";
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import Grid from "@material-ui/core/Grid";

import {Loading} from '../'
import {FILTER_PLACEHOLDER, EMPTY_STRING} from "../../Helpers/constants";
import {useStyles} from '../../Utils/sharedStyles'

const TreePane = lazy(() => import('../TreePane'))

const TreeWithSearch = (props) => {
  const classes = useStyles();
  const [filterInput, setFilterInput] = useState(EMPTY_STRING);
  const handleFilterInput = (event) => setFilterInput(event.target.value)
  const resetFilter = () => setFilterInput(EMPTY_STRING)

  return(
    <Grid container direction="column" justify="flex-start" >
      <FormControl noValidate autoComplete="off">
        <Input
          className={classes.searchField}
          id="filter_loc_sensors"
          placeholder={FILTER_PLACEHOLDER}
          onChange={handleFilterInput}
          value={filterInput}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={(
            filterInput !== EMPTY_STRING ? 
            <InputAdornment position="end" onClick={resetFilter}>
              <ClearIcon fontSize="small" />
            </InputAdornment>
            : 
            <></>
          )
          }
        />
      </FormControl>
      <div>
        <Suspense fallback={<Loading />}>
          <TreePane
            useFilters={[filterInput, setFilterInput]}
            {...props}
          />
        </Suspense>
      </div>
    </Grid>
  )
  
}

export default TreeWithSearch