import React, {useState, useEffect, lazy, Suspense} from 'react'
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Link} from 'react-router-dom'
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import LocationIcon from '@material-ui/icons/LocationOn';
import Drawer from '@material-ui/core/Drawer';
import { Typography } from '@material-ui/core';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { SensorDataBox, Loading } from '../';
import {fetchById} from '../../Helpers/APIHelper'
import {SENSORS, REFRESH_PERIOD} from '../../Helpers/constants'

const SensorFullDetails = lazy(() => import('../SensorFullDetails'))

const sensorStyles = makeStyles(theme => ({
  sensorInner : {
    padding: theme.spacing(.5, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  itemDetails: {
    display: 'flex',
    alignItems: 'center',
  },
  sensorActions: {
    display: 'flex',
    alignItems: 'center',
  },
  iconSpacing: {
    color: 'inherit',
    marginLeft: theme.spacing(2)
  },
  noData: {
    padding: theme.spacing(1),
    opacity: 0.5,
  },
  checkbox: {
    padding: 0,
    marginRight: theme.spacing(2),
  }
}))

const SensorLinks = ({item, activeVars, handleActiveVars, useSensors}) => {
  const styles = sensorStyles()
  
  const WHITELISTS = {
    LEVEL_ONE: ['clientsWiFi', 'temperatureC' , 'CO2ppm'],
    LEVEL_TWO: ['clientsBLE', 'motionEvent', 'batteryLevelPct'],
  }
  const theme = useTheme()
  const mqIsTablet = useMediaQuery(theme.breakpoints.down('sm'))
  const mqIsMobile = useMediaQuery(theme.breakpoints.down('xs'))
  
  const getDataWhitelist = () => (
    mqIsMobile ? 
      []  
    : 
    (mqIsTablet ?
      [...WHITELISTS.LEVEL_ONE]
    : 
      [...WHITELISTS.LEVEL_ONE , ...WHITELISTS.LEVEL_TWO]
    )
  )

  const sensorDataWhitelist = getDataWhitelist()
  
  const [selectedSensors, setSelectedSensors] = useSensors

  const [sensorData, setSensorData] = useState({id: item, name: "Loading"});
  
  const [refresh, setRefresh] = useState(0);

  // REFRESHING DATA EACH 5 MINUTES
  useEffect(() => {
    let interval = setInterval(() => {
      setRefresh(refresh + 1)
      clearInterval(interval)
    }, REFRESH_PERIOD * 60 * 1000)  

    return () => {
      clearInterval(interval)
    }
  }, [refresh])

  useEffect(() => {
    fetchById(SENSORS, item, {}, null)
      .then(response => {
        if(response) {
          setSensorData(response)
        } else {
          setSensorData({id: item, name: "Not found"})
        }
      })
      .catch(() => {})
  }, [item, refresh]);

  
  const [activeSensor, setActiveSensor] = useState(null)
  const toggleAdmin = (sensorId) => setActiveSensor(sensorId)
  
  const isActive = ({varName}) => activeVars.includes(varName)
  
  const sensorsToShow = (sensorData) => sensorData.data.filter(({varName}) => sensorDataWhitelist.includes(varName))
  const sensorsInDropDown = (sensorData) => sensorData.data.filter(({varName}) => !sensorDataWhitelist.includes(varName))

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isSelected = () => selectedSensors.includes(item)

  const handleChange = () => {
    if(isSelected()) setSelectedSensors(selectedSensors.filter(id => id !== item))
    else setSelectedSensors([...selectedSensors, item])
  }

  return (
    <>
      <div className={styles.sensorInner}>
        <div className={styles.itemDetails}>
          <Checkbox className={styles.checkbox} checked={selectedSensors.includes(item)} onChange={handleChange} name={item} />
          <Typography variant={mqIsMobile ? 'caption' :  'body1'}>{sensorData && sensorData.name}</Typography>
        </div>
        <div className={styles.sensorInner}>
          
          {sensorData !== null && sensorData.data && sensorData.data.length > 0 ? 
          <>
            {sensorsToShow(sensorData).map(
              (sensorData, index) => <SensorDataBox active={isActive(sensorData)} handleActiveVars={handleActiveVars} key={index} data={sensorData} />
            )}
            {sensorsInDropDown(sensorData).length > 0 && 
              <div>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="more_varnames"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                >
                  {sensorsInDropDown(sensorData).map(
                    (sensorData, index) => (
                      <MenuItem key={index} onClick={handleClose}>
                        <SensorDataBox noIcon={true} handleActiveVars={handleActiveVars} data={sensorData} />
                      </MenuItem>
                    )
                  )}
                </Menu>
              </div>
            }
          </>
          :
            <Typography variant="caption" className={styles.noData}>Sensor seems to be offline</Typography>
          }
        </div>
        <div className={styles.sensorActions}>
          {!!sensorData.position && 
            <IconButton component={Link} edge="start" color="inherit" className={styles.iconSpacing} to={`/datamap?location=${sensorData.location.replace("#", "").split("#").join(",")}`} aria-label="home">
              <LocationIcon />
            </IconButton>
          }
          <IconButton edge="start" color="inherit" className={styles.iconSpacing} onClick={() => toggleAdmin(item)} aria-label="home">
            <MenuIcon />
          </IconButton>
        </div>
      </div>
      <Drawer open={activeSensor !== null} anchor="right" onClose={() => toggleAdmin(null)}>
        <Suspense fallback={<Loading />}>
          <SensorFullDetails sensor={sensorData} />
        </Suspense>
      </Drawer>
    </>
  )
}

export default SensorLinks
