import {sensorDataDictionary, TEMPERATURE, CO2, dataColours, dataBounds} from './constants'
import {getSensorHistory} from './APIHelper'


// Determine if given timestamp (in ms) is older than threshold in minutes
export const isExpired = (date, treshold = 30) => {
  const dat = new Date(date).getTime()
  const now = new Date().getTime()
  return now - dat > treshold * 60 * 1000
}

export const sumClients = (clients, location) => {
  return clients
    .filter(({time, location: locId}) => locId.startsWith(location.id) && !isExpired(time * 1000)) // Filter for sensors in a sub-location of the location
    .reduce((sum, {value}) => sum + value, 0); // Sum the counts of each sensor
}

export const groupByKey = (arr, key) => {
  return arr.reduce(
    (acc, item) => {
    if(acc[item[key]]) {
      acc[item[key]].push(item) 
      return acc                 
    } 
    
    return ({
      ...acc,
      [item[key]]: [item]
    })
  }, {}) 
}

export const countSensors = (clients) => {
  const sensorsList = groupByKey(clients, "id")

  return Object.entries(sensorsList).reduce((sum, [, vars]) => {
    if(vars.some(({time}) => !isExpired(time * 1000))) return sum + 1
    return sum
  }, 0)
  
}

export const getVarnameBounds = (clients, location) => {
  const sensors = clients.filter(({location: locId, time}) => locId.startsWith(location.id) && !isExpired(time * 1000))
  if(sensors.length === 0) return null
  sensors.sort(({value: A}, {value: B}) => B - A )
  return ({
    location: location.id,
    max: sensors[0].value,
    min: sensors[sensors.length - 1].value
  })
}

export const getLocationLastUpdate = (variables) => {
  if(variables.length === 0) return null;
  const [{time}] = variables.sort(({time: A}, {time: B}) => B - A)
  return time;
}

export const parseParams = (params) => (
  Object.entries(params).reduce(
    (acc, [key, arr], index) => {
      const arrString = arr.reduce((acc, val, index) => `${acc}${index === 0 ? '' : '&'}${key}=${val}`, '')
      return `${acc}${index === 0 ? '' : '&'}${arrString}` 
    }
  , '')
)

/* For plotting data allow different plotly styles for varNames */
export const getPlotStyle = (varId) => {
  switch(varId) {
    case "clientsWiFi": return {
      type: 'scatter',
      line: {width: 1, shape: 'vh'},
      fill: 'tozeroy'
    }
    default: return {type: 'scatter'}
  }
}

const someInRange = (data, min, max) => data.some(({y}) => y.some(item => item < max && item > min ));
const minDataValue = (dataArray) => dataArray.reduce((minVal, {y}) => Math.min(minVal, Math.min(...y)), 9999999999999);
const maxDataValue = (dataArray) => dataArray.reduce((maxVal, {y}) => Math.max(maxVal, Math.max(...y)), -9999999999999);
  //Math.min(minVal, Math.min(y)));

// For generating background shapes for plots e.g. highlight good data range
export const chartShapes = (varName, data, {type, ref}) => {
  const config = {
    type,
    fillcolor: '#d3d3d3',
    opacity: 0.2,
    line: {
      width: 0
    }
  }

  switch(varName) {
    case TEMPERATURE:
      if(!someInRange(data, dataBounds.degC.cold, dataBounds.degC.hot)) return null;
      return ([{
        ...config,
        fillcolor: dataColours.green,
        yref: `y${ref + 1}`,
        xref: 'paper',
        x0: 0,
        y0: Math.max(dataBounds.degC.cold, minDataValue(data)),
        x1: 1,
        y1: Math.min(dataBounds.degC.hot, maxDataValue(data)),
      }])
    case CO2:
      if(someInRange(data, dataBounds.CO2.low, dataBounds.CO2.high)) {

        return ([{
          ...config,
          fillcolor: dataColours.orange,
          yref: `y${ref + 1}`,
          xref: 'paper',
          x0: 0,
          y0: Math.max(dataBounds.CO2.low, minDataValue(data)),
          x1: 1,
          y1: Math.min(dataBounds.CO2.high, maxDataValue(data)),
        },
        ...(someInRange(data, dataBounds.CO2.high, 5000) ? [{
            ...config,
            fillcolor: dataColours.red,
            yref: `y${ref + 1}`,
            xref: 'paper',
            x0: 0,
            y0: Math.min(dataBounds.CO2.high, maxDataValue(data)),
            x1: 1,
            y1: maxDataValue(data)+50,
          }] : [])
        ])
      }
      return null;
    default:
      return null;
  }
}

export const getPlotLayout = (selectedSensors, varName, sensorsList) => {
  const initialLayout = {
    autosize: true,
    // Don't include title to save space
//    title: `Plot of Sensors: ${selectedSensors.reduce((acc, sensorId, index) => {
//      const sensor = extractSensorData(sensorId, sensorsList)
//      return(`${acc} ${sensor && sensor.name}${selectedSensors.length > 0 ? ',' : ''}`)
//    }, "")}`
    // Set margins to maximise plot area (need to use automargin on axes)
    margin: {
      l: 10,
      r: 10,
      b: 10,
      t: 10,
      pad: 0
    },
    // Use automargin so labels aren't cut off
    xaxis: {
      automargin: true,
      tickformatstops: [
        // Adjust formatting for depending on time between ticks
        {
          "dtickrange": [null, 1000],
          "value": "%H:%M:%S.%L ms"
        },
        {
          "dtickrange": [1000, 60000],
          "value": "%H:%M:%S"
        },
        {
          "dtickrange": [60000, 3600000],
          "value": "%H:%M:%S \n %a %e %b, %Y"
        },
        {
          "dtickrange": [3600000, 86400000],
          "value": "%H:%M \n %a %e %b, %Y"
        },
        {
          "dtickrange": [86400000, 604800000],
          "value": "%e. %b \n %Y"
        },
        {
          "dtickrange": [604800000, "M1"],
          "value": "%e. %b \n %Y"
        },
        {
          "dtickrange": ["M1", "M12"],
          "value": "%b %Y"
        },
        {
          "dtickrange": ["M12", null],
          "value": "%Y"
        }
      ]
    }
}

  const layout = varName.reduce((acc, varId, index) => {
    const {title, metric} = sensorDataDictionary[varId] || {title: varId, metric: null}
    let returnLayout;

    if(index === 0) {
      returnLayout = {
        title: `${title} ${(metric ? `(${metric})` : "")}`,
        automargin: true,
      }
    }
    else {
      returnLayout = {
        title: `${title} ${(metric ? `(${metric})` : "")}`,
        titlefont: {color: 'rgb(148, 103, 189)'},
        tickfont: {color: 'rgb(148, 103, 189)'},
        overlaying: 'y',
        side: 'right',
        automargin: true,
      }
    }
    return ({
      ...acc,
      [`yaxis${index > 0 ? index + 1 : ''}`]: returnLayout,
    })
  }, initialLayout)

  return layout;
}

export const convertDateRange = (dateRange) => {
  let tstart = null;
  let tend = null;

  if(!dateRange) return ({tstart, tend})
  const [startDate, endDate] = dateRange;
  if (startDate.toString() === endDate.toString()) {
    // When only selecting one day picker seems to just return one time in the day
    // Cover the 24hour day window for the time
    let dstart = new Date(startDate);
    dstart.setHours(0);
    dstart.setMinutes(0);
    dstart.setSeconds(0);
    tstart = Math.floor(dstart.getTime()/1000);
    tend = tstart + 24*60*60;
  } else {
    // Cover the date range but including to the end of the end day
    tstart = Math.floor(new Date(startDate).getTime()/1000);
    let dend = new Date(endDate);
    dend.setHours(23);
    dend.setMinutes(59);
    dend.setSeconds(59);
    tend = Math.floor(dend.getTime()/1000);
  }

  return ({tend, tstart})
}

/* Fetches and formats plot data for plotly */
export const fetchPlotData = async (varName, selectedSensors, sensorsList, dateRange) => {
  // Convert date range into integer times (in seconds) (TODO check timezone)
  const {tstart, tend} = convertDateRange(dateRange)

  const traces = await varName.reduce(async (trace, varId, varIndex) => {
    const {title, metric} = sensorDataDictionary[varId] || {title: varId, metric: null}

    const data = await selectedSensors.reduce(async (sensors, sensorId) => {

      const sensor = extractSensorData(sensorId, sensorsList)

      // Chart type e.g. "scatter" or "bar"
      // and any additional specific styling
      const plotStyle = getPlotStyle(varId);

      const sensorHistory = await getSensorHistory(sensorId, varId, tstart, tend)
        .then(res => {
          if(!res) return null;

          return({
            ...plotStyle,
            x: res.time.map(i => new Date(i * 1000)),
            y: res.value,
            name: `${sensor && sensor.name} - ${title} ${(metric || "")}`,
            hovertemplate: `%{y} ${(metric || "")} - %{x} <br> ${sensor.name} <extra></extra>`,
            yaxis: `y${varIndex > 0 ? varIndex + 1 : ''}`
          })
        }).catch(err => {})

      const prSensors = await sensors;
      if(!sensorHistory) return sensors
      return [...prSensors, sensorHistory]
    }, [])

    const prTrace = await trace;

    return [...prTrace, ...data]
  }, [])
  return traces;
}

export const extractSensorData = (sensorId, sensors) => sensors.find(({id}) => id === sensorId)

export const countAllSensors = (sensors, {id}) => {
  let allSensorsInLocation = 0;
  if(sensors && sensors.length > 0  && sensors[0].id !== 0) {
    allSensorsInLocation = sensors.filter(({location}) => location.indexOf(id) >= 0).length
  }
  return allSensorsInLocation
}

export const getLocationTreeProps = (location, {sensors, clientsWiFi, temprature, co2, battery}) => {
  const filteredVars = [...co2, ...temprature, ...battery, ...clientsWiFi].filter((item) => item.location.startsWith(location.id))
  const clientSum = sumClients(clientsWiFi, location)
  const activeSensors = countSensors(filteredVars, location)
  const tempratureBounds = getVarnameBounds(temprature, location)
  const co2Bounds = getVarnameBounds(co2, location)
  const batteryBounds = getVarnameBounds(battery, location)
  const lastUpdate = getLocationLastUpdate(filteredVars)
  const allSensors = countAllSensors(sensors, location)
  
  return({
    clientSum,
    allSensors,
    activeSensors,
    tempratureBounds,
    co2Bounds,
    batteryBounds,
    filteredVars,
    lastUpdate,
  })
}

export const getSensorProps = (sensor, {clientsWiFi, temprature, co2, battery}) => {
  const latestClientsWiFi = clientsWiFi.find(item => item.id === sensor.id)
  const sensorCO2 = co2.find(item => item.id === sensor.id)
  const sensorBattery = battery.find(item => item.id === sensor.id)
  const sensorTemprature = temprature.find(item => item.id === sensor.id)
  const filteredVars = [...co2, ...temprature, ...battery, ...clientsWiFi].filter(({id}) => id === sensor.id)
  const lastUpdate = getLocationLastUpdate(filteredVars)

  return ({
    latestClientsWiFi,
    sensorCO2,
    sensorBattery,
    sensorTemprature,
    filteredVars,
    lastUpdate
  })
}

export const parseDecimals = (number, decimal) => {
  const regex = new RegExp(`(-*)(\\d+)\\.(\\d{${decimal}})`)
  const match = regex.exec(number);
  if(match) {
    return `${match[1]}${match[2]}.${match[3]}`
  }
  return number;
}

export const getLastMotionColor = (polygonId, sliderValue, sensorData = []) => {
  const polyData = sensorData.filter((item) =>
    // using toString() to cope with number and string type ids
    (!!item.position.polygon) && (item.position.polygon.toString() === polygonId.toString())
  )
  if (!polyData[0]) return 'white'
  const time = polyData[0].time;
  const d = Math.trunc(new Date().getTime() / 1000) - Math.trunc(time);
  return d > sliderValue[1] * 60 ? 
    dataColours.greenPastel :
    d > sliderValue[0] * 60 && d < sliderValue[1] * 60 ? dataColours.orange : dataColours.redBlood
}

export const renderMinute = (num, shorten = true) => {
  const label = shorten ? 'min' : 'minute'
  return `${num} ${label}${parseInt(num) > 1 ? 's' : ''}`
}

export const getFeatureDataFromEvent = (event, sensorData) => {
  const {sourceTarget: {feature}} = event;
  const data = sensorData.find(item => item.position && item.position.polygon && item.position.polygon === feature.id)
  return ({data, feature});
}

export const formatISODate = date => {
  const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  return `${date.getFullYear()}-${month}-${date.getDate()}`
}
