import React, {lazy, Suspense, useState, useRef} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import {Link} from 'react-router-dom'
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
// import { Link as RouterLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faSmog, faTemperatureLow, faWifi, faCaretUp, faCaretDown, faCircle, faBroadcastTower, faCog, faMapMarker } from '@fortawesome/free-solid-svg-icons'

import Moment from 'react-moment';

import {useTreeItemStyles} from '../../Utils/sharedStyles'
import {sensorDataDictionary, EMPTY_STRING} from '../../Helpers/constants'
import Loading from '../Loading';

const LocationConfig = lazy(() => import('../../Views/LocationConfig'))

const useStyles = makeStyles(theme => ({
  treeLabel: {
    display: 'grid',
    gridRowGap: theme.spacing(1),
    padding: theme.spacing(1),
    margin: theme.spacing(1.5),
    cursor: 'pointer',
    '&:hover': {
      boxShadow: theme.shadows[5],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
    }
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '.8em',
    padding: theme.spacing(1, 0),
    flex: 1,
    overflowY: 'auto'
  },
  boundsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: theme.spacing(3.5),
    // background: theme.palette.background.paper,
    position: 'sticky',
    justifyContent: 'center',
    left: 0,
    zIndex: 2,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    zIndex: 1,
  },
  icon: {
    color: theme.palette.primary.light,
    marginRight: theme.spacing(.5)
  },
  caretUp: {
    color: theme.palette.secondary.light,
    marginRight: theme.spacing(.5)
  },
  caretDown: {
    color: theme.palette.primary.dark,
    marginRight: theme.spacing(.5)
  },
  unit: {
    opacity: .5,
    marginLeft: theme.spacing(.5)
  },
  caption: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
  },
  deactive: {
    '&:not(:hover)' : {
      opacity: .7,
    }
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  sensorIndicator: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
    fontSize: '.5em'
  },
  activeSensor: {
    color: theme.palette.success.main,
  },
  headerItem: {
    '&:hover, &:active': {
      boxShadow: 'none'
    },
    cursor: 'initial',
    margin: 0, 
    padding: 0,
    background: 'transparent',
    marginTop: theme.spacing(1),
  },
  configDivider: {
    margin: theme.spacing(0, 1),
    marginRight: theme.spacing(0),
  },
  config: {
    color: 'inherit',
    textDecoration: 'none',
    marginLeft: theme.spacing(2),

    "&:hover" : {
      color: theme.palette.primary.main
    }
  },
  sensorPositionDrawer: {
    width: '50vw',

    [theme.breakpoints.down('xs')] : {
      width: '90vw',
    }
  }
}))

export const BoundariesVarBox = ({bound, unit, icon}) => {
  const styles = useStyles()
  return (
    <div className={styles.wrapper}>
      <FontAwesomeIcon className={styles.icon} icon={icon} />
      <div className={styles.boundsWrapper}>
        <div className={styles.wrapper}>{bound.max} <small className={styles.unit}>{unit}</small></div>
        <div className={styles.wrapper}>{bound.min} <small className={styles.unit}>{unit}</small></div>
      </div>
    </div>
  )
}

// Mostly taken from material-ui StyledTreeItem
// Tree Item for our sensors, what should we display in this?
const LocationTreeItem = (props) => {
  const classes = useTreeItemStyles();
  const styles = useStyles()
  
  const {
    clientSum,
    activeSensors,
    allSensors,
    tempratureBounds,
    co2Bounds,
    lastUpdate,
    humidityBounds,
    locationName,
    locationId,
    setLocationId,
    headerItem,
    useFilters,
    userIsAdmin,
  } = props;

  const [, setFilters] = useFilters

  const time = lastUpdate ? new Date(lastUpdate * 1000) : null
  const isActive = time !== null && (new Date().getTime() - time.getTime() < (30 * 60 * 1000))

  const configRef = useRef(null)
  const handleClick = () => {
    setFilters(EMPTY_STRING)
    setLocationId(locationId)
  }

  const [sensorPositionOpen, setSensorPosition] = useState(false)

  const handleLink = () => {
    setSensorPosition(true)
  }
  
  return(
    <Paper onClick={handleClick} className={`${styles.treeLabel} ${!isActive ? styles.deactive : ''} ${headerItem ? styles.headerItem : ''}`} elevation={headerItem ? 0 : 1}>
      <div className={styles.caption}>
        <Typography variant="body2" className={classes.labelText}>
          <FontAwesomeIcon size='xs' icon={faCircle} className={`${styles.sensorIndicator} ${isActive ? styles.activeSensor : ''}`} />
          <strong>{locationName}</strong>
        </Typography>
        <Typography variant="caption" className={classes.labelText}>
          <small>{isActive ? <Moment fromNow ago date={time} /> : <>No active sensor</> }</small>
          {headerItem && 
          <>
            <Divider className={styles.configDivider} flexItem orientation="vertical" />
            <Link to={`/datamap?location=${locationId.replace("#", "").split("#").join(",")}`} className={styles.config} onClick={handleLink}>
              <FontAwesomeIcon icon={faMapMarker} />
            </Link>
            {userIsAdmin &&   
              <span className={styles.config} ref={configRef} onClick={handleLink}>
                <FontAwesomeIcon icon={faUpload} />
              </span>
            }
          </>
          }
        </Typography>
      </div>
      {isActive && 
        <div className={styles.root}>
          {(tempratureBounds || co2Bounds || humidityBounds) && 
            <div className={styles.boundsWrapper}>
              <div className={styles.wrapper}>
                <FontAwesomeIcon className={styles.caretUp} icon={faCaretUp} />
              </div>
              <div className={styles.wrapper}>
                <FontAwesomeIcon className={styles.caretDown} icon={faCaretDown} />
              </div>
            </div>
          }
          {tempratureBounds && <BoundariesVarBox icon={faTemperatureLow} bound={tempratureBounds} unit={sensorDataDictionary.temperatureC.metric} />}
          {co2Bounds && <BoundariesVarBox icon={faSmog} bound={co2Bounds} unit={sensorDataDictionary.CO2ppm.metric} />}
          {/* {humidityBounds && <BoundariesVarBox icon={faTint} bound={humidityBounds} unit={sensorDataDictionary.relativeHumidity.metric} />} */}
          <div className={styles.wrapper}>
            <FontAwesomeIcon className={styles.icon} icon={faBroadcastTower} />
            <Typography variant="caption" className={`${classes.labelText} ${styles.nowrap}`}>
              {activeSensors} / {allSensors}
            </Typography>
          </div>
          <div className={styles.wrapper}>
            <FontAwesomeIcon className={styles.icon} icon={faWifi} />
            <Typography variant="caption" className={`${classes.labelText} ${styles.nowrap}`}>
              {clientSum}
            </Typography>
          </div>
        </div>
      }
      {
        userIsAdmin ?
        <Drawer 
          variant="temporary"
          open={sensorPositionOpen} 
          anchor="right"
          onClose={() => setSensorPosition()}
          >
          <div className={styles.sensorPositionDrawer}>
            <Suspense fallback={<Loading />}>
              <LocationConfig locationName={locationName} locationId={locationId} />
            </Suspense>
          </div>
        </Drawer>
        : 
        <></>
      }
    </Paper>
  )
}

export default LocationTreeItem
