import React from 'react';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { makeStyles } from "@material-ui/core/styles";
import {sensorDataDictionary} from '../../Helpers/constants'
import { isExpired } from '../../Helpers/functions';

const sensorDataStyles = makeStyles(theme => ({
  dataBoxInner : {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: theme.spacing(1, .5),
    borderRadius: theme.spacing(5),
    paddingRight: theme.spacing(1),
    margin: theme.spacing(0, .5),
    cursor: 'pointer',
    '&:hover' : {
      background: theme.palette.grey[100],
    }
  },
  dataValueBox: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  dividerSpace: {
    margin: theme.spacing(0, 1.5)
  },
  metric: {
    margin: theme.spacing(0, 0.25),
    opacity: 0.5,
  },
  icon: {
    color: theme.palette.primary.light,
    margin: theme.spacing(0, 1.5)
  },
  active: {
    background: theme.palette.grey[100],
    boxShadow: `0 0 0 1px ${theme.palette.primary.light}`
  },
  old: {
    "&:not(:hover)": {
      filter: 'grayscale(100%)',
      opacity: .75,
    }
  }
}))

const SensorDataBox = (props) => {
  const {handleActiveVars, active, data, noIcon} = props;
  const styles = sensorDataStyles();
  const {varName, value} = data;
  const meta = sensorDataDictionary[varName] || {icon: null, metric: null, title: varName};

  const isOld = isExpired(data.time * 1000)

  const handleClick = () => handleActiveVars(varName)

  return (
    meta !== null ? 
    <div title={isOld ? "Data is older than 30 minutes ago" : ""} onClick={handleClick} className={`${styles.dataBoxInner} ${active ? styles.active : ''} ${isOld ? styles.old : ''}`}>
      {!noIcon && meta.icon !== null ? 
        <FontAwesomeIcon className={styles.icon} icon={meta.icon} /> 
        : 
        <>
          <Typography variant="caption">{meta.title}</Typography>
          <Divider orientation="vertical" flexItem className={styles.dividerSpace} />
        </> 
      }
      <div className={styles.dataValueBox}>
        <Typography variant="body1">{value}</Typography>
        {meta.metric && <Typography variant="caption" className={styles.metric}>{meta.metric}</Typography>}
      </div>
    </div>
    :
    <></>
  )
}

export default SensorDataBox;
