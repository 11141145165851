// Base React
import React, { useState, useEffect } from "react";

// Signal Icons - Required as used by RSSILookup

import SignalWifi0BarRoundedIcon from '@material-ui/icons/SignalWifi0BarRounded';
import SignalWifi1BarRoundedIcon from '@material-ui/icons/SignalWifi1BarRounded';
import SignalWifi2BarRoundedIcon from '@material-ui/icons/SignalWifi2BarRounded';
import SignalWifi3BarRoundedIcon from '@material-ui/icons/SignalWifi3BarRounded';
import SignalWifi4BarRoundedIcon from '@material-ui/icons/SignalWifi4BarRounded';
import SignalWifiOffRoundedIcon from '@material-ui/icons/SignalWifiOff';

// Tooltip

import Tooltip from '@material-ui/core/Tooltip';

const RSSILookup = ({RSSI}) => {

    var lookup = {0:-998,1:-100,2:-80,3:-60,4:-40,5:0}

    const entries = Object.entries(lookup)

    // Check RSSI against the look up

    const Map = {
        5: SignalWifi4BarRoundedIcon,
        4: SignalWifi3BarRoundedIcon,
        3: SignalWifi2BarRoundedIcon,
        2: SignalWifi1BarRoundedIcon,
        1: SignalWifi0BarRoundedIcon,
        0: SignalWifiOffRoundedIcon

      }

    var IconName;

    // Loop through lookup
    for (const [key, value] of entries) {
        if (RSSI < value) {
            //console.log(`Found lookup ${value} and ${key}`)
            IconName = Map[key];
            break
        
        }
    }
    
    //console.log(IconName)

    // https://stackoverflow.com/questions/48863490/dynamically-rendering-component-from-string-reactjs

    return (
        <Tooltip title={RSSI}>
        <IconName 
            color='primary'
        />

      </Tooltip>
    )

}

export default RSSILookup