import React from "react";

import { Link } from "react-router-dom";

import Hidden from "@material-ui/core/Hidden";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Fab from "@material-ui/core/Fab";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import MenuIcon from "@material-ui/icons/Menu";
import DevicesIcon from "@material-ui/icons/Devices";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconLogout from "@material-ui/icons/ExitToApp";

import { useStyles } from "../../Utils/sharedStyles";
import { menuItems } from "../../Helpers/configs";

const MobileMenu = () => {
  const styles = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={styles.mobileMenuIcon}>
        <IconButton
          aria-controls="simple-menu"
          color="inherit"
          aria-haspopup="true"
          onClick={handleOpen}
        >
          <MenuIcon />
        </IconButton>
      </div>
      <Drawer anchor="bottom" open={open} onClose={handleClose}>
        <List>
          {menuItems.map(({ text, icon: Icon, anchor }, index) => (
            <Link
              key={index}
              to={anchor}
              className={styles.resetColor}
              color="inherit"
            >
              <ListItem button key={index} onClick={handleClose}>
                <ListItemIcon>
                  <Icon color="inherit" />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
    </>
  );
};

const Header = ({ handleLogOut, user, useTree }) => {
  const styles = useStyles();

  const [treeOpen, toggleTree] = useTree;

  const handleMenu = () => toggleTree(!treeOpen);

  return (
    <>
      <AppBar className={styles.appBar} position="static">
        <Toolbar>
          <Hidden mdUp>
            <MobileMenu />
          </Hidden>
          <Typography variant="h6">LightFi</Typography>
          <Hidden smDown>
            <div className={styles.title}>
              {menuItems.map(({ icon: Icon, anchor }, index) => (
                <Link key={index} to={anchor} className={styles.resetColor}>
                  <IconButton edge="start" color="inherit" aria-label="home">
                    <Icon />
                  </IconButton>
                </Link>
              ))}
            </div>
          </Hidden>
          <Typography variant="body1" className={styles.userPadding}>
            Hello, {user?.attributes !== undefined && user.attributes.email}
          </Typography>
          <IconButton onClick={handleLogOut} variant="outlined" color="inherit">
            <IconLogout />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        <div className={styles.fabIcon}>
          <Fab
            onClick={handleMenu}
            color="secondary"
            aria-label="Sensor Browser"
          >
            {treeOpen ? <CloseIcon /> : <DevicesIcon />}
          </Fab>
        </div>
      </Hidden>
    </>
  );
};

export default Header;
