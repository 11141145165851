// Base React
import React, {lazy, Suspense} from "react";
import { Link } from "react-router-dom";

// Styles
import { makeStyles } from "@material-ui/core/styles";
import { useStyles } from '../../Utils/sharedStyles';

// Material UI
import IconButton from '@material-ui/core/IconButton';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import BuildIcon from '@material-ui/icons/Build';
import Grid from  "@material-ui/core/Grid"

import {Loading} from '../../Components'

// Helpers
import { sensorsDictionary } from "../../Helpers/constants";

const SensorDataList = lazy(() => import('../../Components/SensorDataList'))

const sensorSideBarStyles = makeStyles(theme => ({
  innerPadding: {
    paddingRight: theme.spacing(0),
    minWidth: '30vw',
    marginTop: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1, 0)
  },
  paddingLeft: {
    paddingLeft: theme.spacing(2),
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  configButton: {
    padding: theme.spacing(0),
  }
}))

const SensorFullDetails = ({sensor}) => {
  const classes = sensorSideBarStyles();
  const styles = useStyles()
  // Get the sensorId from the query string (or use default)

  // TODO Show position object, link to/embed positionpicker
  // TODO Metadata has time of attribute change, show this (small/hidden by default)
  return (
    <div className={classes.innerPadding} >
      {sensor !== null && <>
        <Grid container alignItems="center" justify="space-between" className={classes.headerBox}>
          <div>
            <Typography variant="h6">
            {sensor.name}
            </Typography>
            <Typography variant="caption">
              {sensor.id}
            </Typography>
          </div>
          <IconButton component={Link} to={`/sensors/${sensor.id}`}  edge="start" color="inherit" aria-label="home" className={styles.resetColor}>
            <BuildIcon />
          </IconButton>
        </Grid>
        <Divider className={classes.margin} />
        <List>
          {Object.entries(sensor).map(
            ([key, value], index) => (
              key === "metadata" || key === "position"  ? 
                null 
              :
                (key === "data" ?
                  <div key={index}>
                    <Divider />
                    <List>
                      <Suspense fallback={<Loading />}>
                        <SensorDataList data={value} />
                      </Suspense>
                    </List>
                  </div>
                :
                  <ListItem key={index} className={classes.listItem}>
                    <ListItemText secondary={sensorsDictionary[key]} /> 
                    <ListItemText primary={value} />
                  </ListItem>
                )
            )
          )}
        </List>
      </>
      }
    </div>
  );
}

export default SensorFullDetails;
