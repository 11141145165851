//Base React
import React, { useState, useRef, useEffect, useCallback, lazy, Suspense } from 'react'
import ReactDOMServer from 'react-dom/server';
// Styles

// Material UI
import Grid from '@material-ui/core/Grid'

// Leaflet
import { divIcon } from 'leaflet';
import { Map, Marker, TileLayer, GeoJSON, FeatureGroup } from 'react-leaflet'
//import '../../Utils/leaflet.css';

// Helpers
import { fetchData, makeOptions, LOOKFOR, } from '../../Helpers/APIHelper';
import { getLastMotionColor, getFeatureDataFromEvent } from '../../Helpers/functions';
import { LOCATIONS, MOTIONEVENT, dataBounds } from '../../Helpers/constants';

import { makeStyles } from '@material-ui/core/styles';

import {Loading} from '../';
import {CustomMarker, CircleColourMarker, ThermalMarker} from '../Markers'

const SensorMarkerDetails = lazy(() => import('../../Components/SensorMarkerDetails'))

const makeMarker = (sensor) => {
  if (sensor.varName === "temperatureC") {
    return circleMarker(sensor)
  } else if (sensor.varName === "CO2ppm") {
    return circleMarker(sensor)
  } else {
    return customPinMarker(sensor)
  }
}

const customPinMarker = (sensor) => divIcon({
  className: "my-custom-pin",
  html:  ReactDOMServer.renderToString(<CustomMarker sensor={sensor} />)
})

const circleMarker = (sensor) => divIcon({
  className: "my-custom-pin",
  html:  ReactDOMServer.renderToString(<CircleColourMarker sensor={sensor} />)
})

const thermalMarker = () => divIcon({
  className: "my-custom-thermal",
  html:  ReactDOMServer.renderToString(<ThermalMarker />)
})

const MapValueMarkers = ({useChildren, useLocation, varName, wheelZoom = true}) => {
  const styles = makeStyles({
    wrapper: {
      height: '100%',
      position: 'relative',
    }
  })()
  const [sliderValue, setSliderValue] = useState([1, 5]);
  const [sensorData, setSensorData] = useState([]);
  const [floorPlan, setFloorPlan] = useState(null);
  const [geoJSONKey, setGeoJSONKey] = useState(null);

  const [locationId, setLocationId] = useLocation
  const [{locations, sensors}] = useChildren

  const mapRef = useRef();
  const geoJSONRef = useRef();
  const fgRef = useRef()

  // NEW STATES:
  const [activeLocations, setActiveLocations] = useState(null);

  // Styles polygons based on the data time/value
  const geoJSONStyle = (feature = null) => {
    const id = feature ? (feature.id || feature.properties.id) : null;
    // For motionEvents colour polygon by time of last motion
    if (varName === "motionEvent") {
      return ({
        ...(id && {fillColor: getLastMotionColor(id, sliderValue, sensorData)}),
        color: 'black',
        weight: 1,
        fillOpacity: 1,
        highlight: true
      });
    }
    // TODO for other varNames color associated polygon by value?
    return ({
      fillColor: 'white',
      color: 'black',
      weight: 1,
      fillOpacity: 1,
      highlight: true
    });
}

  // Extracts data we want to display from the full sensor list
  const updateData = (sensors, varName) => {
    const result = sensors
      .filter(item => ("position" in item) && ("data" in item))
      .reduce((acc, {id, data, position}) => {
        const activeVarName = data.find(item => item.varName === varName)
        if(!activeVarName) return acc;
        return [...acc, {
          ...activeVarName,
          id,
          position,
        }]
      }, [])
    setSensorData(result)
  }

  const setMapBounds = useCallback(() => {
    const constraint = sensorData.some(sensor => "position" in sensor) || (!!activeLocations && activeLocations.length > 0) || !!floorPlan;
    const {current: map} = mapRef;
    const {current: fg} = fgRef;
    if (constraint && !!map && !!fg) {
      const bounds = fg.leafletElement.getBounds();
      const {_northEast, _southWest} = bounds;
      if ((_northEast && _southWest) && (_northEast.lat !== _southWest.lat)) {
        map.leafletElement.fitBounds(bounds);
      }
    }
  }, [sensorData, activeLocations, floorPlan])

  // When data for the current location is updated process the new data
  useEffect(() => {
    if(locations.length >= 0) {
      const active = locations.filter((item) => "position" in item)
      setActiveLocations(active);
    }

    if(sensors.length > 0) {
      updateData(sensors, varName);
    } else {
      setSensorData([])
    }
  }, [locations, sensors, varName])

  // Try to get the floorplan of the parent location
  useEffect(() => {
    if(sensors.length > 0) {
      const options = makeOptions({
        lookFor: LOOKFOR.FLOORPLAN
      })

      fetchData(LOCATIONS, locationId, options)
        .then(data => {
          setFloorPlan(data);
        })
    }
  }, [locationId, sensors])

  useEffect(() => {
    // Need to update key to re-render https://stackoverflow.com/questions/44155385/rendering-geojson-with-react-leaflet
    setGeoJSONKey(locationId)
  }, [floorPlan])

  useEffect(() => {
    setMapBounds();
  }, [geoJSONKey, activeLocations])


  const [visibleSensor, setVisibleSensor] = useState(null)
  const [fixedSensor, setFixSensor] = useState(false)
  const [activeFeature, setActiveFeature] = useState(null)

  const clearVisibleSensor = () => {
    if(fixedSensor) return
    setFixSensor(false);
    setVisibleSensor(null);
  }

  const handleVisibleSensor = (sensorId, fix) => {
    const sensor = sensors.find(({id}) => id === sensorId)
    if(sensor && fix && varName !== MOTIONEVENT) setFixSensor(true)
    setVisibleSensor(sensor || null);
  }

  const hideDetailBox = () => {
    setFixSensor(false);
    setVisibleSensor(null);
  }

  const clearHighlightedFeature = () => {
    setActiveFeature(null);
  }

  const highlightFeature = (event) => {
    const {data, feature} = getFeatureDataFromEvent(event, sensors)
    if(!!data) {
      const sensor = sensors
        .filter(item => "data" in item)
        .find(({id}) => id === data.id)
      setActiveFeature(feature)
      setVisibleSensor(sensor)
      if(event.type === 'click') {
        setFixSensor(true)
      }
    }
  }

  // For interaction with GeoJSON
  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: highlightFeature.bind(this),
      click: highlightFeature.bind(this),
      mouseout: clearHighlightedFeature.bind(this)
    });
  }

  return (
    <Grid className={styles.wrapper} container direction="column">
      <Map
        center={{ lat: 51.505, lng: -0.09 }}
        zoom={13}
        maxZoom={23}
        scrollWheelZoom={wheelZoom}
        ref={mapRef}
        style={{height: '100%', width: '100%'}}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
          subdomains='abcd'
        />
        <FeatureGroup ref={fgRef}>
          <GeoJSON
            ref={geoJSONRef}
            key={geoJSONKey}
            data={floorPlan}
            style={geoJSONStyle}
            onEachFeature={onEachFeature}
          />
          {// For thermal irSignatures data show markers on map
            sensorData.filter(
              sensor => ("geoPoints" in (sensor || {}))
              && (Math.trunc(new Date().getTime() / 1000) - Math.trunc(sensor.time) < dataBounds.timeoutMins*60)
            ).map(
            sensor => {
              return (
                sensor.geoPoints.map( point =>
                  {
                    return(
                      <Marker
                        key={`point-${point[0]}-${point[1]}`}
                        position={{lat: point[0], lng: point[1]}}
                        icon={thermalMarker()}
                      />
                    )
                  })
              )
            })
          }
          {//Add markers for sensor data values
            sensorData.filter(
            // Remove data older than dataBounds.timeoutMins minutes
            // Always include battery level - makes it easier to spot dead batteries
              sensor => (
                sensor.varName !== "motionEvent" && 
                (
                  sensor.varName === "batteryLevelPct" ||
                  sensor.varName === "onlineStatus" ||
                  Math.trunc(new Date().getTime() / 1000) - Math.trunc(sensor.time) < dataBounds.timeoutMins*60
                )
              )
            ).map(
              sensor => {
                return(
                <Marker
                  onmouseover={() => handleVisibleSensor(sensor.id, false)}
                  onmouseout={clearVisibleSensor}
                  onClick={() => handleVisibleSensor(sensor.id, true)}
                  key={sensor.id}
                  position={{lat: sensor.position.lat, lng: sensor.position.lng}}
                  icon={makeMarker(sensor)}
                />
              )
            })
          }
          {// Add markers for sub-locations of the current location
            !!activeLocations &&
            activeLocations.map(
              ({id, position: {lat, lng}}, index) => (
                <Marker
                  key={id}
                  position={{lat, lng}}
                  onClick={() => setLocationId(id)}
                />
              )
            )
          }
        </FeatureGroup>
      </Map>
      <Suspense fallback={<Loading />}>
        <SensorMarkerDetails
          visibleSensor={visibleSensor}
          fixedSensor={fixedSensor}
          sensors={sensors}
          varName={varName}
          feature={activeFeature}
          actions={{
            hideDetailBox,
          }}
          useSlider={[sliderValue, setSliderValue]}
        />
      </Suspense>
    </Grid>
  );
}

export default MapValueMarkers;
