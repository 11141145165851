// Base React
import React, { useState, useEffect, lazy } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

// Styles 
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from '../../Utils/sharedStyles';

// Material UI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography"
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BuildIcon from '@material-ui/icons/Build';

// Helpers
import {fetchByIdShadow, postById, updateByIdShadow, fetchById, updateById} from '../../Helpers/APIHelper'
import {SENSORS, SUBSENSOR_TYPES} from '../../Helpers/constants'

// Components

const RSSILookup = lazy(() => import('../../Components/RSSILookup'))

// Styles

const SubSensorListStyles = makeStyles(theme => ({

  expansionDetail: {
    display: 'grid',
    gridTemplateColumns: '2fr 2fr 36px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "& > *": {
      paddingLeft: 0,
      paddingRight: 0,
      alignSelf: 'center',
    },
  },
  shadowGridHeader: {
    height: '48px',
    display: 'grid',

    gridTemplateColumns: '75px 2fr 2fr 2fr 1fr 36px',
    padding: '0 16px 0 16px',
    "& > *": {
      paddingLeft: 0,
    },
  }, 
  expansionSummary: {
    padding: '0 16px 0 16px',
  },
  shadowSummary: {
    display: 'grid',
    gridTemplateColumns: '75px 2fr 2fr 2fr 1fr',
    width: '100%',
    "& > *": {
      alignSelf: 'center',
    },
  },     
  shadowHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 16px 8px 16px',
    borderBottomColor: 'rgba(0, 0, 0, 0.12)',
    borderBottom: '1px solid',
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  button: {
    minWidth: 'auto',
    padding: '4px',
  },

  textField: {
    marginTop: '4px',
  },
  centerIcon: {
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  rightIcon: {
    display:'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

  },
  rightPadding:{
    paddingRight:'6px',
  }

}));

const SensorShadowSubSensorList = ({sensor, shadow, refreshShadow}) => {

  /* Things we do here:
   *  - check if subsensor section exists
   *  - decode subsensor visible from zlib+base64 encoded
   *  - parse visible sensors and config from shadow
   *  - list subsensors (separate SubSensorListItem component for each)
   *  - ...?
   */
  //console.log(shadow)

  // Import Styles and Classes
  const classes = SubSensorListStyles();

  // Check if Shadow has subsensors section
  // note: uses "optional chaining"
  if (!shadow?.state?.reported?.visible?.subsensors_v1?.sensors) {
    return (
      <div>
        <Typography variant="body1" >No known subsensors</Typography>
      </div>
    )
  }

  // Zlib
  const zlib = require('zlib');
  var zlibSubsensors = shadow.state.reported.visible.subsensors_v1.sensors

  // Info on inflating zlib without callback
  // https://stackoverflow.com/a/39800991
  var inflatedSubsensors = JSON.parse(zlib.inflateSync(new Buffer(zlibSubsensors, 'base64')).toString());
  //console.log(inflatedSubsensors);

  // Check shadow for sub sensors in config
  var desiredSubSensors = shadow.state.desired?.config?.subsensors_v1 || {};
  var reportedSubSensors = shadow.state.reported?.config?.subsensors_v1 || {};

  return (
    <>
      <div className={classes.marginBottom}>

        <div className={classes.shadowHeader}>
          <Typography variant="body1" >
            <b>Shadow Sub Sensors </b>
          </Typography>

        </div>

        <List className={classes.shadowGridHeader}>

          <ListItem><Typography variant="body2" color="textSecondary">Active</Typography></ListItem>
          <ListItem><Typography variant="body2" color="textSecondary">Type</Typography></ListItem>
          <ListItem><Typography variant="body2" color="textSecondary">Address</Typography></ListItem>
          <ListItem><Typography variant="body2" color="textSecondary">Signal Strength Indicator</Typography></ListItem>
          <ListItem><Typography variant="body2" color="textSecondary">Last Seen</Typography></ListItem>


        </List>

        {Object.entries(inflatedSubsensors).map(([k1, v1],i1) => <SubSensorListItem
          index={i1}
          mac={k1}
          sensorProps={v1}
          parentSensor={sensor}
          reportedSubSensors={reportedSubSensors}
          desiredSubSensors={desiredSubSensors}
          refreshShadow = {refreshShadow}
          /> 
        )}

      </div>


    </>
  )

}
const SubSensorListItem = ({index, mac, sensorProps, parentSensor, reportedSubSensors, desiredSubSensors, refreshShadow}) => {
  const classes = SubSensorListStyles();

  // Generate sensor id
  const shortMAC = mac.replace(/:/g,"").toUpperCase();
  const sensorId = sensorProps.type+'-'+ shortMAC;

  // In Progress - check if shadow update has not been processed
  const inReported = reportedSubSensors.hasOwnProperty(shortMAC);
  const inDesired = desiredSubSensors.hasOwnProperty(shortMAC) && (desiredSubSensors['shortMAC'] !== {"delete": true});
  const [inProgress, setInProgress] = useState((inDesired && !inReported) || (!inDesired && inReported));

  // Checkbox
  const [open, setOpen] = useState(false);
  const [subSensorExists, setSubSensorExists] = useState(false);

  const handleCheckBoxChange = (event) => {
    if (event.target.checked === true) {
      console.log("The checkbox is not ticked. Add sub sensor.")
      console.log("The first step is to check if it already exists")
      fetchById(SENSORS, sensorId)
        .then(response => {
          console.log(response)
          if (response.hasOwnProperty("gateway")) {
            console.log("Sub Sensor Already Exists")
            if ( response.gateway == "" ) {
              setSubSensorExists(true);
              handleClickOpen();
            } else {
              console.log("Trying to add a sensor connected to another gateway");
            }
          } else {
            console.log("Sub Sensor Doesn't Exist, try to add it.")  
            setSubSensorExists(false)
            handleClickOpen()      
          }
        });           

    } else {
      console.log("The checkbox is ticked. Remove sub sensor.")
      RemoveSubSensor(event.target.name)
    }
  };

  // Dialog Box
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleSubmit = (subSensorName) => {
    setOpen(false);
    AddSubSensor(subSensorName);
  };

  const AddSubSensor = (subSensorName, createNew) => {
    // Create JSONs for Adding new Sub Sensor
    // GET /sensors/{sensor_id}
    if (subSensorExists) {
      //console.log("Sub Sensor Exists")
      var PUTbody = {
        "gateway": parentSensor.id,
      }

      updateById(SENSORS, sensorId, PUTbody)
        .then(response => {
          // TODO handle if the sensor was updated successfully
          console.log(response)
        }) 

    } else {
      //console.log("Sub Sensor Doesn't Exist, try to add it.")
      var POSTbody = {
        "location": parentSensor.location,
        "name": subSensorName,
        "shortName": subSensorName,
        "timeZone": parentSensor.timeZone,
        "type": SUBSENSOR_TYPES[sensorProps.type].type,
        "gateway": parentSensor.id,
        "hwVersion": SUBSENSOR_TYPES[sensorProps.type].hwVersion,
        "fwVersion": 1,
      }

      //console.log("API POST call here")
      postById(SENSORS, sensorId, POSTbody)
        .then(response => {
          console.log(response)
        })
    }

    // Add to list of configured sensors
    // TODO - Don't do this if subsensor exists and is configured for another gateway sensor
    var PUTbody = {
      "state": {
        "desired": {
          "config": {
            "subsensors_v1": {
              [shortMAC]: {}
            }
          }
        }
      }
    }

    //console.log("API PUT shadow call here")
    updateByIdShadow(SENSORS, parentSensor.id, PUTbody)  
      .then(response => {
        console.log(response)
      })  

    setInProgress(true);

    refreshShadow();
  };

  const RemoveSubSensor  = () => {
    //console.log("Removing Sub Sensor: "+ shortMAC)
    var PUTbody = {
      "state": {
        "desired": {
          "config": {
            "subsensors_v1": {
              [shortMAC]: {"delete": true}
            }
          }
        }
      }
    }

    console.log("API PUT shadow call here")
    console.log(PUTbody)

    updateByIdShadow(SENSORS, parentSensor.id, PUTbody)  
      .then(response => {

        console.log(response)
      })    

    var PUTbody = {

      "gateway": ""

    }

    console.log("API PUT call here")
    console.log(PUTbody)

    updateById(SENSORS, sensorId, PUTbody)
      .then(response => {
        console.log(response)
      })

    setInProgress(true);

    refreshShadow();

  };

  // Expansion Panel 
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Color for removing or adding
  if (inProgress) {
    console.log("Subsensor adding or removing, change color of checkbox")
    var color = "Primary"
  } else {
    var color = "Secondary"
  }

  return (
      <>
      <ExpansionPanel 
        expanded={expanded === 'panel'+index} 
        onChange={handleChange('panel'+index)}
        TransitionProps={{ unmountOnExit: true }}  // This stops the expansion being loaded till clicked
      >

        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header" 
          className={classes.expansionSummary}
        >

          <div className={classes.shadowSummary}>

            {/* Listen Checkbox */}

            <div className={classes.checkBox}>

              <Checkbox
                checked={(reportedSubSensors.hasOwnProperty(shortMAC) || inProgress) ? true : false }
                onChange={handleCheckBoxChange}
                disabled={(SUBSENSOR_TYPES.hasOwnProperty(sensorProps.type)) ? false : true}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                name={mac}
                color={color}
              />
            </div>

            {/* Example List
                                    last_seen: 1588368306
                                    rssi: -85
                                    type: "MS1"
                                    */}

            <Typography>{sensorProps.type}</Typography>
            <Typography>{mac}</Typography>
            <RSSILookup RSSI={sensorProps.rssi} />
            <Typography><Moment fromNow>{new Date(sensorProps.last_seen * 1000)}</Moment></Typography> 


          </div>

        </ExpansionPanelSummary>

        <ExpansionPanelDetails className={classes.expansionDetail}>

          <SubSensorExpandedDetails subSensorId={sensorId} />

        </ExpansionPanelDetails>

      </ExpansionPanel>

      <SubSensorNameDialog subSensorExists={subSensorExists} subSensor={mac} open={open} onSubmit={handleSubmit} onCancel={handleCancel} />   
    </>
  )

}

function SubSensorExpandedDetails(props) {
  // This will load the details of a subsensor when it is expanded in the list
  // TODO - Show more of these details
  const {subSensorId} = props;
  const [exists, setExists] = React.useState(false);
  const [sensorProps, setSensorProps] = React.useState({});

  // Check sensor exists and get it's properties
  useEffect(() => {
    // Get sensor properties
    fetchById(SENSORS, subSensorId)
      .then(response => {
        if (response.hasOwnProperty("id")) {
          //console.log('There is a sensor in the response')
          setExists(true);
          setSensorProps(response);
        }
      })
  }, []);

  return (
    <>
      {exists ?
        <>
          <Typography variant="body2" color="textSecondary">ID: {subSensorId}</Typography>
          <Typography variant="body2" color="textSecondary">Name: {sensorProps.name}</Typography>
          <IconButton
            component={Link}
            to={`/sensors/${subSensorId}`}
            edge="start" color="inherit"
            aria-label="home"
          >
            <BuildIcon />
          </IconButton>

        </>
        :
        <Typography>Sensor not yet created</Typography>}
    </>
  );
}

function SubSensorNameDialog(props) {
  // This shows a dialog when adding a sensor that allows entering it's details
  // TODO - allow entering more details

  const classes = SubSensorListStyles();

  const {subSensorExists, subSensor, onCancel, onSubmit, open } = props;

  const [subSensorName, setSubSensorName] = React.useState(false);

  const handleCancel = () => {
    onCancel();
  }

  const handleSubmit = () => {
    onSubmit(subSensorName);
  }

  const setSubSensorNameValue = (event) => {
    //console.log(event.target.value)
    setSubSensorName(event.target.value);

  }

  return (
    <Dialog onClose={handleCancel} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="form-dialog-title">Add Sub Sensor</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {subSensorExists ? 

            <>Sub sensor address: {subSensor} already exists. Do you want to connect it to this gateway?</>

            :

            <>To add sub sensor address: {subSensor} to this gateway enter a name for the sub sensor and click submit.</>

          }

        </DialogContentText>


        {subSensorExists ?

          <></>

          :

          <div className={classes.form}>
            <TextField
              className={classes.textField}
              onChange={setSubSensorNameValue}
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
            />
          </div>


        }

      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>

    </Dialog>
  );
}

export default SensorShadowSubSensorList
