import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyle = makeStyles(theme => ({
  loadingWrapper: {
    width: '100%',
    minHeight: theme.spacing(10),
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}))

const Loading = () => {
  const styles = useStyle();
  return(
    <div className={styles.loadingWrapper}>
      <FontAwesomeIcon icon={faSpinner} spin />
    </div>
  )
}

export default Loading