import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import {isExpired} from '../../Helpers/functions'
import {sensorDataDictionary} from "../../Helpers/constants";
import Divider from "@material-ui/core/Divider";
import Moment from "react-moment";

const SensorDataListStyle = makeStyles(theme => ({
  listItem: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  },
  gridItem: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridColumnGap: theme.spacing(2),
    alignItems: 'center',
  },
  gridValue: {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridColumnGap: theme.spacing(2),
  },
  icon: {
    color: theme.palette.primary.main,
    opacity: .5,
  },
  date: {
    opacity: .5,
  },
  offline: {
    color: theme.palette.secondary.main
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  noWrap: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }
}))

const SensorDataList = ({data, noDate}) => {
  const styles = SensorDataListStyle();
  return(
    data.map(
      ({varName, time, value}, index) => {
        const meta = sensorDataDictionary[varName] || {icon: null, metric: null, title: varName};
        const isOld = isExpired(time * 1000)
  
        return(
          <div key={index}>
          <ListItem key={index} className={styles.listItem}>
            <div className={styles.gridItem}>
              {meta && meta.icon && <FontAwesomeIcon className={styles.icon} icon={meta.icon} /> }
              {meta &&
                <Typography variant="caption" className={styles.noWrap}>
                  {meta.title}
                </Typography>
              }
            </div>
            <div className={styles.gridValue}>
              <Typography variant="body1">{value} <small>{meta.metric}</small></Typography>
              {!noDate &&
                <Typography variant="caption" className={isOld ? styles.offline : styles.date}>
                  <Moment fromNow>{new Date(time * 1000)}</Moment>
                </Typography>
              }
            </div>
          </ListItem>
          {index !== data.length - 1 && <Divider className={styles.marginLeft} />}
          </div>
        )
      }
    )
  )
}


export default SensorDataList;
