import { faTint, faSmog, faTemperatureLow, faWifi, faLightbulb, faBatteryHalf, faTachometerAlt, faAtom, faNetworkWired, faCloudMeatball } from '@fortawesome/free-solid-svg-icons'
import {faBluetooth} from '@fortawesome/free-brands-svg-icons'

export const sensorsDictionary = {
  port: "Port",
  fwVersion : "Firmware Version",
  hwVersion : "Hardware Version",
  location : "Location",
  shortName: "Short Name",
  latest: "Properties Version",
  id: "Id",
  name: "Name",
  timeZone: "Time Zone",
  type: "Type",
  dataVariant: "Data Variant",
  metadata: "Metadata",
  data: "Data",
}

export const shadowDictionary = {
  addr: "Address:",
  RSSIs: "Signal Strength Indicator:",
  last: "Last Seen",
  '1st': "First Seen",
  chn: "Channel",
  lstn: "Listen",
  man: "Manual",
  str: "Strength",


}

export const sensorDataDictionary = {
  BACnetReads: {
    title: 'BACnet',
    metric: null,
    icon: faNetworkWired,
  },
  batteryLevelPct: {
    title: 'Battery',
    metric: "%",
    icon: faBatteryHalf,
  },
  CO2ppm: {
    title: 'CO2',
    metric: 'ppm',
    icon: faSmog,
  },
  clientsBLE: {
    title: 'Bluetooth Devices',
    metric: null,
    icon: faBluetooth,
  },
  clientsWiFi: {
    title: 'Wifi Devices',
    metric: null,
    icon: faWifi,
  },
  illuminanceArb: {
    title: 'Light Level',
    metric: "arb.",
    icon: faLightbulb,
  },
  irSignatures: {
    title: 'Thermal Signatures',
    metric: null,
    icon: null,
  },
  motionEvent: {
    title: 'Motion Events',
    metric: null,
    icon: null,
  },
  onlineStatus: {
    title: 'Online Status',
    metric: null,
    icon: null,
  },
  pressuremBar: {
    title: 'Pressure',
    metric: "hPa",
    icon: faTachometerAlt,
  },
  particulateMatter: {
    title: 'Paticulate Matter',
    metric: "µg/m³",
    icon: faCloudMeatball,
  },
  relativeHumidity: {
    title: 'Humidity',
    metric: "%",
    icon: faTint,
  },
  temperatureC: {
    title: 'Temperature',
    metric: "°C",
    icon: faTemperatureLow,
  },
  VOCppb: {
    title: 'Volatile Organic Compounds',
    metric: "ppb",
    icon: faAtom,
  }
}

export const SENSORS = 'sensors'
export const LOCATIONS = 'locations'
export const CLIENTSWIFI = 'clientsWiFi'
export const CO2 = 'CO2ppm'
export const HUMADITY = 'relativeHumidity'
export const TEMPERATURE = 'temperatureC'
export const BATTERY = 'batteryLevelPct'
export const MOTIONEVENT = 'motionEvent'
export const THERMAL = 'irSignatures'

export const DAILY = 'daily'
export const LATEST = 'latest'
export const LAST_WEEK= 'last_week'
export const LAST_MONTH= 'last_month'

export const FILTER_PLACEHOLDER = 'Look for Locations, Sensors Names or ID...'
export const EMPTY_STRING = ''

export const REFRESH_PERIOD = 10 // MINUTE

export const RANGES = {
  LATEST,
  DAILY,
  LAST_WEEK, 
  LAST_MONTH
}

export const USER_ADMIN_TYPES = ['owner', 'admin', 'installer']

export const DEFAULT_RANGE = [
  new Date().getTime() - 86400000,
  new Date().getTime()
]

export const SUBSENSOR_TYPES = {
  'AMP': {"type": 'PIRMotionEvents', "hwVersion": "AnkhMaway_PIR"},
  'LF2': {"type": 'BeaconCO2', "hwVersion": "LF_Proto_CO2"},
  'LF4': {"type": 'PIRMotionEvents', "hwVersion": "Proto_PIR"},
  'LF5': {"type": 'BeaconPMCO2', "hwVersion": "LF_Proto_PMCO2"},
  'GAP': {"type": 'IRSignatures', "hwVersion": "GAPPoc-B"},
  'MS1': {"type": 'BeaconTempHum', "hwVersion": "Minew_S1"},
  'JTH': {"type": 'BeaconTempHum', "hwVersion": "Jinou_Temp_Hum"},
}

export const dataColours = {
  red: 'rgb(255, 79, 79)',
  green: 'rgb(129, 232, 39)',
  blue: 'rgb(84, 181, 255)',
  red7: 'rgba(255, 79, 79, 0.7)',
  green7: 'rgba(129, 232, 39, 0.7)',
  blue7: 'rgba(84, 181, 255, 0.7)',
  // colours from https://graphicdesign.stackexchange.com/questions/17446/is-there-a-good-way-to-establish-a-green-yellow-red-colour-scheme
  greenPastel: '#C0FFE0',
  orange: '#FF8400',
  orange7: 'rgba(255, 132, 0, 0.7)',
  redBlood: '#E00000',
  // Alex suggestions for CO2
  CO2low: '#00ff99',
  CO2mid: '#ff9500',
  CO2high: '#ff148a',
  // From graphs
  boxRed: '#CC0000',
  boxAmber: '#FF9900',
  boxGreen: '#0ec254'
}

export const dataBounds = {
  timeoutMins: 30,  // For hiding data older than this time
  degC: {hot: 24, cold: 20},
  CO2: {low: 900, high: 1400}
}
