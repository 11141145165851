
import HomeIcon from '@material-ui/icons/Home';
import RoomIcon from '@material-ui/icons/Room';
import RouterIcon from '@material-ui/icons/Router';

export const menuItems = [{
  text: 'Home',
  anchor: '/',
  icon: HomeIcon,
},{
  text: 'Sensor Data',
  anchor: '/sensordata',
  icon: RouterIcon,
},{  text: 'Sensor Map',
  anchor: '/datamap',
  icon: RoomIcon,
}]
