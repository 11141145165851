import React from 'react';

import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

const LocationBreadcrumbs = ({locationId, locations, handleClick, startFrom, ...rest}) => {
  const firstIndex = 0;
  const locIds = locationId === "#" ?  ["", ""] : locationId.split("#");
  const findLocation = (locId, lastIndex) => {
    if(locId === "") return({
      name: 'All Locations',
      id: "#",
    })

    const location = locations
      .find(({id}) => id.indexOf(`${lastIndex}#${locId}`) >= 0 )
    
      return location
  }

  return (
    <Breadcrumbs 
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      {...rest}
    >
      {locIds
        .slice(firstIndex, locIds.length - 1)
        .map((locId, index) => {
          const lastIndex = index > 0 ? index - 1 : 0
          const {id, name} = findLocation(locId, locIds[lastIndex])

          return (
            handleClick ? 
            <Link style={{cursor: 'pointer'}} color="inherit" key={index} onClick={() => handleClick(id)}>
              {name}
            </Link>
            : 
            <Typography>
              {name}
            </Typography>
          )
        })
      }
    </Breadcrumbs>
  )
}

export default LocationBreadcrumbs;