import { makeStyles } from "@material-ui/core/styles";

const useTreeItemStyles = makeStyles(theme => ({
  flexReverse: {
    flexDirection: 'row-reverse',
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    padding: theme.spacing(1)
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
  }
}));

// Tree Item for our sensors, what should we display in this?
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer * 2,
  },
  toolbar: theme.mixins.toolbar,
  fabIcon: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: theme.zIndex.drawer * 2,
  },
  sideMenu: {
    minWidth: '70vw',
    maxWidth: '90vw',
    [theme.breakpoints.sm]: {
      width: '60vw',
    },
    [theme.breakpoints.xs]: {
      width: '90vw',
    }
  },
  searchField: { 
    margin: theme.spacing(2),
    marginBottom: 0,
  },
  mobileMenuIcon: {
    marginLeft: -1 * theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  mobileMenuItem: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
  },
  mobileIcon: {
    marginRight: theme.spacing(2),
  },
  root: {
    minHeight: '100vh',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(2),
  },
  title: {
    paddingLeft: theme.spacing(2),
    flexGrow: 1,
  },
  userPadding: {
    padding: theme.spacing(0, 2),
    marginLeft: 'auto !important'
  },
  resetColor: {
    color: 'inherit',
    textDecoration: 'none'
  },
  sidebar: {
    minHeight: '100%',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    paddingRight: 0,
  },
}));

export {
  useTreeItemStyles,
  useStyles
}