import React, { useState, createRef, useEffect } from 'react'
import { Map, TileLayer, GeoJSON, FeatureGroup } from 'react-leaflet'
import Typography from '@material-ui/core/Typography';
import UploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/styles/makeStyles';
import { fetchLFApi, postLFApi } from '../../Helpers/APIHelper';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
  icons: {
    marginRight: theme.spacing(1),
  },
  padding: {
    padding: theme.spacing(2),
  },
  margin: {
    marginBottom: theme.spacing(1),
  }
}))

const FloorPlanUpload = (props) => {
  const styles = useStyles();
  const locationId = props.locationID;
  const [file, setFile] = useState(null);
  const [floorPlan, setFloorPlan] = useState(null);
  const [geoJSONKey, setGeoJSONKey] = useState(null);

  const mapRef = createRef();
  const geoJSONRef = createRef();
  const fgRef = createRef()

  // Try to get the floorplan of the parent location
  useEffect(() => {
    const parentLocation = encodeURIComponent(locationId)
    const route = `/locations/${parentLocation}/floorplan`
    fetchLFApi(route)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        }
        else {
          return null;
        };
      })
      .then(resp_json => {
        setFloorPlan(resp_json);
        // Need to update key to re-render https://stackoverflow.com/questions/44155385/rendering-geojson-with-react-leaflet
        setGeoJSONKey(locationId);
      })
  }, [locationId]);

  useEffect(() => {
    // If floorplan has been loaded zoom the map to the floorplan
    if (floorPlan) {
      const map = mapRef.current;
      if (map !== null) {
        const fg = fgRef.current;
        if (fg !== null) {
          const bounds = fg.leafletElement.getBounds();
          map.leafletElement.fitBounds(bounds);
        }
      }
    }
  }, [geoJSONKey])

  useEffect(() => {
    if (file) {
      console.log(file);
      var r = new FileReader();
      r.onload = function(e) {
        var contents = e.target.result;
        console.log( "Got the file.n"
          +"name: " + file.name + "n"
          +"type: " + file.type + "n"
          +"size: " + file.size + " bytesn"
          + "starts with: " + contents.substr(1, contents.indexOf("n"))
        );
        const floorplan = JSON.parse(contents);
        setFloorPlan(floorplan);
        setGeoJSONKey(`${file.name}+${file.lastModified}`);
      }
      r.readAsText(file);
    } else {
      console.log("No File");
    }
  }, [file])

  const onInputChange = (event) => {
    var uploadFile = event.target.files[0];
    console.log(uploadFile);
    if (uploadFile.type === "application/json" || uploadFile.type === "application/geo+json") {
      setFile(uploadFile);
    } else {
      setFile(false);
    }
    console.log("input change");
  }

  const geoJSONStyle = (feature) => {
    return {
      //fillColor: 'white',
      fillColor: getColorByType(feature.properties.type),
      color: 'black',
      weight: 1,
      fillOpacity: 1,
      highlight: true
    };
  }

  const getColorByType = (featureType) => {
    switch (featureType) {
      case "furniture.desk":
        return('brown');
      case "area.room":
        return('#E3E3E3'); //light grey
      case "area.building":
        return('grey');
      default:
        return('white')
    }
  }

  const onEachPolygonFeature = (feature, layer) => {
    layer.on({
      click: getPolygonClicked
    })

  }

  const getPolygonClicked = (e, layer) => {
    console.log(e.target.feature);
  }

  const fileUploadHandler = () => {
    console.log("file upload change");
    const parentLocation = encodeURIComponent(locationId)
    const route = `/locations/${parentLocation}/floorplan`
    // TODO only allow this if admin
    postLFApi(route, floorPlan)
      .then(response => console.log(response));
      // TODO handle response to indicate success/failure
  }

  return (
    <div>
      <form method="post" action="#" id="#">
        <div className={styles.padding}>
          <div className={styles.margin}>
            <Typography variant="body1">
              Please upload floor plan.
            </Typography>
            <Typography variant="caption">
              File must be json or geojson
            </Typography>
          </div>
          <div className={styles.margin}>
            <input className={`${styles.input} form-control`} type="file" name="file" id="choose_file" onChange={onInputChange}/>
            <label htmlFor="choose_file" className={styles.margin}>
              <Button component="span" size="small" variant="outlined">
                {!file ? 'Choose your file' : file.name }
              </Button>
            </label>
          </div>
          <div className={styles.margin}>
            <Button variant="contained" color="primary" disabled={file === null} onClick={fileUploadHandler}>
              <UploadIcon fontSize="small" className={styles.icons} /> Upload File
            </Button>
          </div>
        </div>
      </form>
      <Map
        center={{ lat: 51.505, lng: -0.09 }}
        zoom={13}
        ref={mapRef}
        style={{height: '500px', width: '100%'}}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxZoom={23}
          maxNativeZoom={19}
        />
        <FeatureGroup ref={fgRef}>
          <GeoJSON
            ref={geoJSONRef}
            key={geoJSONKey}
            data={floorPlan}
            style={geoJSONStyle}
            onEachFeature={onEachPolygonFeature}
          />
        </FeatureGroup>
      </Map>
    </div>
  );
}

export default FloorPlanUpload;
