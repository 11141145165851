// Base React
import React, { useState, useEffect } from "react";
import Moment from "react-moment";

// Styles 
import { makeStyles } from '@material-ui/core/styles';

// Material UI

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography"
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Helpers
import { shadowDictionary } from "../../Helpers/constants";

// Styles
const APListStyles = makeStyles(theme => ({

  
    shadowGridHeader: {
        height: '48px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr  36px',
        padding: '0 16px 0 16px',
        "& > *": {
            paddingLeft: 0,
          },
    }, 
    shadowGridDetail: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
        "& > *": {
            paddingLeft: 0,
          },
    }, 
    shadowHeader: {
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        borderBottomColor: 'rgba(0, 0, 0, 0.12)',
        borderBottom: '1px solid',
    },

    shadowSummary: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        width: '100%',
    },
    expansionSummary: {
        padding: '0 16px 0 16px',
    },
  }));

const SensorShadowAPListOld = ({shadow}) => {

    // Setup Styles
    const classes = APListStyles();

    // Expansion Panel 

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    console.log(shadow)

    return  (

        <div className={classes.noPadding}>

            <div className={classes.shadowHeader}>
            <Typography variant="body1">
                <b>Shadow Access Point List (Old Type)</b>
            </Typography>

            {/* Shadow list update time */}

            <Typography variant="caption">
                <span>Updated <Moment fromNow>{new Date(shadow.apListUpdate * 1000)}</Moment></span>
            </Typography>
            </div>                  
                    
                <>
                <List className={classes.shadowGridHeader}>

                <ListItem><Typography variant="body2" color="textSecondary">SSID</Typography></ListItem>
                <ListItem><Typography variant="body2" color="textSecondary">Address</Typography></ListItem>

                </List>
                
                {Object.entries(shadow.apList).map(([k1, v1], i1) => (

                <ExpansionPanel expanded={expanded === 'panel'+i1} onChange={handleChange('panel'+i1)}>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={classes.expansionSummary}
                >

                <div className={classes.shadowSummary}>

                {Object.entries(v1).map(([k2, v2], i2) => ( 

                    // Address
                    k2 === "ssid" ? 
                    <>
            
                    <Typography>{v2}</Typography>
                    </>

                    :
                    k2 === "addr" ? 
                    <>

                    <Typography>{v2}</Typography>
                    </>

                    :
                    ""
                    
                ))} 

                </div>

                </ExpansionPanelSummary>
                
                <ExpansionPanelDetails>
                <List className={classes.shadowGridDetail}>

                {/* Loop through for headers */}

                {Object.entries(v1).map(([k2, v2], i2) => (

                    // Not addr and ssid
                    k2 !== "ssid" & k2 !== "addr" ? 
                    <ListItem><Typography variant="body2" color="textSecondary">{shadowDictionary[k2]}</Typography></ListItem>

                    :
                    ""

                ))} 

                {/* Loop through for values */}
                {Object.entries(v1).map(([k2, v2], i2) => (

                    // Not addr and ssid
                    k2 !== "ssid" & k2 !== "addr"? 

                    k2 ==="last" || k2 === "1st" ?
                    <ListItem><Moment fromNow>{new Date(v2 * 1000)}</Moment></ListItem>
                    :
                    <ListItem>{v2}</ListItem>

                    :
                    ""
                ))} 

                </List> 
                </ExpansionPanelDetails> 
                </ExpansionPanel>

                ))} 
                </>

            </div>

    )

}

export default SensorShadowAPListOld
