import React, { useState, useEffect} from "react";
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

import {LocationBreadcrumbs, Loading} from '../'

const useStyles = makeStyles(theme => ({
  padding: {
    padding: theme.spacing(2),
  },
  paddingBottom: {
    paddingBottom: theme.spacing(1),
  },
  breadcrumb: {
    paddingBottom: theme.spacing(1),
    fontSize: ".9em",
  },
  item: {
    padding: theme.spacing(1.5, 2),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create('all', theme.transitions.easing.easeInOut),

    "&:hover" : {
      boxShadow: theme.shadows[5],
      cursor: 'pointer'
    }
  },
  inactive: {
    opacity: .5,
    filter: 'grayscale(100%)',

    '&:hover': {
      opacity: 1,
    }
  }
}))

// TODO - seriously lacking styling (could be redone in some other way even)
// TODO - Some sort of store to avoid multiple API calls
const LocationSelect = ({idle, useLocation, locationNames, useChildren}) => {
  const styles = useStyles();
  const [locationId, setLocationId] = useLocation;
  const [children] = useChildren  // Direct children of the parent location
  const {locations, sensors} = children;
  const [currentLocation, setCurrentLocation] = useState(null);

  const [filteredLocations, setFilteredLocations] = useState(null);
  const [innerIdle, setInnerIdle] = useState(true);

  useEffect(() => {
    setInnerIdle(true)
    if(locations.length >= 0) {
      const active = locations.filter((item) => "position" in item)
      const inactive = locations.filter((item) => !("position" in item))

      setFilteredLocations({
        active,
        inactive,
      })
      setInnerIdle(false)
    }
  }, [locations])

  useEffect(() => {
    if(locationId !== '#') {
      const current = locationNames.find(({id}) => id === locationId)
      setCurrentLocation(current)
    } else {
      setCurrentLocation(null)
    }
  }, [locationNames, locationId])

  return(
    <div className={styles.padding}>
      <div className={styles.paddingBottom}>
        <LocationBreadcrumbs 
          locations={locationNames}
          locationId={locationId}
          handleClick={setLocationId}
          maxItems={3}
          className={styles.breadcrumb}
        />
        {currentLocation &&
          <Typography variant='body1' className={styles.paddingBottom}>
            <strong>{currentLocation.name}</strong>
          </Typography>
        }
        <Divider />
      </div>
      <div>
        {idle || innerIdle ? 
        <Loading />
          :
          <>
            {!!filteredLocations && filteredLocations.active.map(({id, name}) =>
              <LocationListItem 
                key={id}
                locationId={id} 
                setLocation={setLocationId} 
                name={name} 
              />
            )}
          
            {!!filteredLocations && filteredLocations.inactive.length > 0 && 
              <>
                <div className={styles.paddingBottom}>
                  <Divider />
                </div>
                {filteredLocations.inactive.map(({id, name}) =>
                  <LocationListItem
                    inactive={true}
                    key={id}
                    locationId={id} 
                    setLocation={setLocationId} 
                    name={name} 
                  />
                )}
              </>
            }
          </>
        }
        {locations.length > 0 && sensors.length > 0 &&
          <Divider />
        }
      </div>
    </div>
  )
}

const LocationListItem = ({name, setLocation, inactive, locationId}) => {
  const styles = useStyles();
  const handleClick = () => {
    setLocation(locationId)
  }

  return  (
    <Paper 
      className={classnames(styles.item , {
        [styles.inactive]: inactive,
      })} 
      onClick={handleClick} 
    >
      <Typography variant="body1">
        {name}
      </Typography>
    </Paper>
  );
}

export default LocationSelect
