import React from 'react'
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Devices from "@material-ui/icons/Devices";
import TrackChanges from "@material-ui/icons/TrackChanges";
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import AdjustIcon from '@material-ui/icons/Adjust';
import {makeStyles} from "@material-ui/core/styles";

import Moment from 'react-moment';

import {sensorDataDictionary} from '../../Helpers/constants'
import {useTreeItemStyles} from '../../Utils/sharedStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTint, faSmog, faTemperatureLow, faWifi, faBatteryHalf } from '@fortawesome/free-solid-svg-icons'
import { Divider } from '@material-ui/core';
import { isExpired } from '../../Helpers/functions';

// Having JSX in here like this doesn't feel like the best way of doing things
// Probably should have a completely new component for this that just accepts type
// (could also use FontAwesomeIcon as alternative?)
const sensorTypesDictionary = {
  BeaconCO2: {
    desc: 'Carbon Dioxide',
    icon: <CloudQueueIcon />,
  },
  BeaconPMCO2: {
    desc: 'CO2 and Particulates',
    icon: <CloudQueueIcon />,
  },
  BeaconTempHum: {
    title: 'Temperature and Humidity',
    icon: <AcUnitIcon />,
  },
  IRSignatures: {
    title: 'Motion event',
    icon: <AdjustIcon />,
  },
  "LF-WiFISensor": {
    title: 'WiFi Sensor',
    icon: <Devices />,
  },
  PIRMotionEvents: {
    title: 'Motion event',
    icon: <TrackChanges />,
  }
}

const useStyles = makeStyles(theme => ({
  treeLabel: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1, 0),
    justifyContent: 'space-between',
    borderRadius: theme.spacing(.5),
    transition: theme.transitions.create('all', theme.transitions.easing.easeInOut),

    '&:hover' :{
      background: theme.palette.background.paper,
      boxShadow: theme.shadows[3],
      cursor: 'pointer'
    }
  },
  details: {
    display: 'grid',
    gridRowGap: theme.spacing(.5)
  },
  lowerBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
    overflowY: 'auto',
  },
  upperBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    paddingLeft: 0,
  },
  icon: {
    color: theme.palette.primary.light,
    marginRight: theme.spacing(1)
  },
  unit: {
    opacity: .5,
    marginLeft: theme.spacing(1)
  },
  caption: {
    display: 'grid',
    gridTemplateColumns: `${theme.spacing(4)}px ${theme.spacing(1)}px auto`,
    gridColumnGap: theme.spacing(1),
    padding: theme.spacing(.5, 1),
    width: '100%',
    boxSizing: 'border-box'
  },
  offline: {
    color: theme.palette.secondary.main
  },
  checkbox: {
    padding: '0',
  },
  deactive: {
    filter: 'grayscale(1)',
    opacity: .5
  }
}))

export const SensorDetailBox = ({item, icon, unit}) => {
  const styles = useStyles();
  const classes = useTreeItemStyles();

  const {value, time} = item;
  const expired = isExpired(time * 1000)
  return(
    <div 
      className={`${styles.wrapper} ${expired ? styles.deactive : ''}`}
      title={expired ? 'Data is older than 30 minutes ago' : ''}
    >
      <FontAwesomeIcon className={styles.icon} icon={icon} />
      <Typography variant="caption" className={classes.labelText}>
        {value}
        {unit ? <small className={styles.unit}>{unit}</small> : null}
      </Typography>
    </div>
  )
}

const SensorTreeItem = (props) => {
  const styles = useStyles();
  const {useSensors, sensor, clients, co2, battery, temperature, lastUpdate} = props;
  
  const {name, type} = sensor;

  const [selectedSensors, setSelectedSensors] = useSensors;
  
  const time = lastUpdate && !isExpired(lastUpdate * 1000) ? (new Date(lastUpdate * 1000)) : null

  const isOfline = () => (time === null)

  const isSelected = () => selectedSensors.includes(sensor.id)

  const handleChange = () => {
    if(isSelected()) setSelectedSensors(selectedSensors.filter(id => id !== sensor.id))
    else setSelectedSensors([...selectedSensors, sensor.id])
  }

  return(
    <div onClick={handleChange} className={styles.treeLabel}>
      <Typography variant="caption" className={styles.caption}>
        <div>
          {isSelected() ? 
            <Checkbox className={styles.checkbox} checked={selectedSensors.includes(sensor.id)} onChange={handleChange} name={sensor.id} />
            :
              (sensorTypesDictionary[type] || {}).icon || <TrackChanges />
          }
        </div>
        <Divider orientation="vertical" flexItem />
        <div className={styles.details}>
          <div className={styles.upperBar}>
            <strong>{name}</strong> 
            <div>
              {isOfline() ?
                <span className={styles.offline}>offline</span>
                :
                <small className={styles.unit}><Moment fromNow date={time} /></small>
              }
            </div>
          </div>
          {!isOfline() ?
            <div className={styles.lowerBar}>
              {battery && <SensorDetailBox icon={faBatteryHalf} item={battery} unit={sensorDataDictionary.batteryLevelPct.metric} />}
              {clients && <SensorDetailBox icon={faWifi} unit={null} item={clients} />}
              {temperature && <SensorDetailBox icon={faTemperatureLow} item={temperature} unit={sensorDataDictionary.temperatureC.metric} />}
              {co2 && <SensorDetailBox icon={faSmog} item={co2} unit={sensorDataDictionary.CO2ppm.metric} />}
            </div>  
          : null }
        </div>
      </Typography>
    </div>
  )
}

export default SensorTreeItem
