//Base React
import React, { useState, createRef, useEffect } from 'react'

// Material UI

// Leaflet
import { Map, TileLayer, Marker, Popup, GeoJSON, FeatureGroup } from 'react-leaflet'

// Helpers
import { getFloorPlan, updateById, fetchById } from '../../Helpers/APIHelper';
import { SENSORS } from '../../Helpers/constants';
import { parseDecimals } from '../../Helpers/functions';
import Grid from '@material-ui/core/Grid';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';


// TODO make this a component on the SensorConfig View
const PositionPicker = ({id}) => {
	// const {sensorId: param} = useParams();

	// Setup Styles

	
	const sensorId = id || "LF-E4956E4E4F2F";

	const [centre, setCentre] = useState({
		lat: 51.505,
		lng: -0.09
	});
	const [zoom, setZoom] = useState(13);
	const [markerPos, setMarkerPos] = useState({
		lat: 51.505,
		lng: -0.09
	});
	const [draggable, setDraggable] = useState(true);

	const [polygon, setPolygon] = useState(null);

	const [sensorProps, setSensorProps] = useState({});
	const [floorPlan, setFloorPlan] = useState(null);
	const [floorPlanKey, setFloorPlanKey] = useState(null);

	const mapRef = createRef();
	const refmarker = createRef();
	const geoJSONRef = createRef();
	const fgRef = createRef()

	const toggleDraggable = () => {
		setDraggable(!draggable)
	}

	const updatePosition = () => {
		const marker = refmarker.current
		if (marker != null) {
			setMarkerPos(marker.leafletElement.getLatLng())
		}
	}

	const setPositionInDB = () => {
		let body = {
			position: {
				lat: markerPos.lat,
				lng: markerPos.lng
			}
		};
		if (polygon) {
			body.position.polygon = polygon
		}

		updateById(SENSORS, sensorId, body)
			.then(response => console.log(response))
	}

	// default GeoJSON styling
	const geoJSONStyle = (feature) => {
    let id = feature.id;
    if (!id) {
      id = feature.properties.id;
    }
		return {
      // polygon is the defined polygon id of the sensor
      // id is the polygon id specified in the json
      // Convert to string to handle number type ids
      fillColor: (!polygon) ? 'white' : (!id) ? 'grey' : (id.toString() === polygon.toString()) ? 'red' : (!sensorProps.position) ? 'white' : (!sensorProps.position.polygon) ? 'white' : (id.toString() === sensorProps.position.polygon.toString()) ? 'blue' : 'white',
			//fillColor: getColor(feature.id),
			color: 'black',
			weight: 1,
			fillOpacity: 1,
			highlight: true
		};
	}

	const onEachPolygonFeature = (feature, layer) => {
		layer.on({
			click: getPolygonClicked
		})

	}

	const getPolygonClicked = (e, layer) => {
		console.log(e.target.feature);
    if (e.target.feature.id) {
      setPolygon(e.target.feature.id);
    } else if (e.target.feature.properties.id) {
      setPolygon(e.target.feature.properties.id);
    }
	}

	// load properties for the selected sensor
	useEffect(() => {
		fetchById(SENSORS, sensorId)
			.then(res => {
				setSensorProps(res);
				const {
					position
				} = res;
				if (position && position.lat && position.lng) {
					setMarkerPos({
						lat: position.lat,
						lng: position.lng,
					});
					if (position && position.polygon) {
						setPolygon(position.polygon)
					}
					setZoom(19); // We've got a location so zoom in
					setCentre({
						lat: position.lat,
						lng: position.lng
					});
				}
			});
	}, [sensorId]);

	// Try to get the floorplan of the parent location
	useEffect(() => {
		if (sensorProps.location) {
			getFloorPlan(sensorProps.location)
				.then(response => {
					console.log('floorplan', response)
					if(!response) return
					setFloorPlan(response);
					// Need to update key to re-render https://stackoverflow.com/questions/44155385/rendering-geojson-with-react-leaflet
					setFloorPlanKey(sensorProps.location);
					// TODO Zoom map to floorplan extent
				})
		}
	}, [sensorProps]);

	useEffect(() => {
		const map = mapRef.current;
		if (map !== null) {
			const fg = fgRef.current;
			if (fg !== null) {
				const bounds = fg.leafletElement.getBounds();
				map.leafletElement.fitBounds(bounds);
			}
		}

	}, [floorPlanKey])

	return (
		<Grid container>
			<Grid item xs={12} sm={4}>
				<List>
					<ListItem>
						<ListItemText
							primary={parseDecimals(markerPos.lat, 6)}
							secondary={"Marker Lat"}
						/>
						<ListItemText
							primary={parseDecimals(markerPos.lng, 6)}
							secondary={"Marker Lng"}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={polygon || "None"}
							secondary={"Polygon"}
						/>
					</ListItem>
					<Divider />
					<ListItem>
						<ListItemText
							primary={`${parseDecimals((sensorProps.position || {}).lat, 6) || 'None'}`}
							secondary={"Current Lat"}
						/>
						<ListItemText
							primary={`${parseDecimals((sensorProps.position || {}).lng, 6) || 'None'}`}
							secondary={"Current Lng"}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary={(sensorProps.position || {}).polygon || 'None'}
							secondary={"Current Polygon"}
						/>
					</ListItem>
					<ListItem>
						<Button
							variant="contained"
							color="primary"
							disabled={(markerPos.lat === (sensorProps.position || {}).lat) && (markerPos.lng === (sensorProps.position || {}).lng)}
							onClick={setPositionInDB}
						>
							Assign new location
						</Button>
					</ListItem>
				</List>
			</Grid>
			<Grid item xs={12} sm={8}>
				<Map
					center={centre}
					zoom={zoom}
					ref={mapRef}
          style={{height: '100%'}}
				>
					<TileLayer
						attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxZoom={23}
            maxNativeZoom={19}
					/>
					<FeatureGroup ref={fgRef}>
						<Marker
							draggable={draggable}
							onDragend={updatePosition}
							position={markerPos}
							ref={refmarker}>
							<Popup minWidth={90}>
									<span onClick={toggleDraggable}>
											{draggable ? 'DRAG MARKER' : 'MARKER FIXED'}
									</span>
							</Popup>
						</Marker>
						<GeoJSON 
							ref={geoJSONRef}
							key={floorPlanKey}
							data={floorPlan}
							style={geoJSONStyle}
							onEachFeature={onEachPolygonFeature}
						/>
					</FeatureGroup>
				</Map>
			</Grid>
		</Grid>
	);
}

// const MapTestClick = () => {
// 	const [lat, setLat] = useState(51.505);
// 	const [lng, setLng] = useState(-0.09);
// 	const [hasLocation, setHasLocation] = useState(false);
// 	const zoom = 13;
// 	const position = [lat, lng];

// 	const mapRef = createRef();

// 	const handleClick = () => {
// 		console.log("handleClick")
// 		const map = mapRef.current
// 		if (map != null) {
// 			map.leafletElement.setView([51.505, -1.09], 13)
// 		}
// 	}

// 	return (
// 		<Map
// 			center={position}
// 			length={4}
// 			zoom={zoom}
// 			ref={mapRef}
// 			onClick={handleClick}
// 		>
// 			<TileLayer
// 				attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
// 				url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
// 			/>
// 			<Marker position={position}>
// 				<Popup>
// 					A pretty CSS3 popup. <br /> Easily customizable.
// 				</Popup>
// 			</Marker>
// 		</Map>

// 	);
// }

// const MapTestBasic = () => {
// 	const lat = 51.505;
// 	const lng = -0.09
// 	const zoom = 13;
// 	const position = [lat, lng];
// 	return (
// 		<Map
// 			center={position}
// 			zoom={zoom}
// 		>
// 			<TileLayer
// 				attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
// 				url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
// 			/>
// 			<Marker position={position}>
// 				<Popup>
// 					A pretty CSS3 popup. <br /> Easily customizable.
// 				</Popup>
// 			</Marker>
// 	</Map>

// 	);
// }

export default PositionPicker;
