import React from 'react'
import { LocationTreeItem, SensorTreeItem, LocationBreadcrumbs} from '../'
import { getLocationTreeProps, getSensorProps } from '../../Helpers/functions'
import { EMPTY_STRING } from '../../Helpers/constants'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  header: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  margin: {
    marginTop: theme.spacing(1),
  },
  locationBar: {
    paddingTop: theme.spacing(1)
  },
  breadcrumb: {
    fontSize: ".9em",
  }
}))

export const Location = ({location, useFilters, setLocationId, headerItem, sensors, clientsWiFi, temprature, co2, battery, userIsAdmin}) => {
  const {
    clientSum,
    allSensors, 
    activeSensors,
    tempratureBounds,
    co2Bounds,
    batteryBounds,
    lastUpdate,
  } = getLocationTreeProps(location, {sensors, clientsWiFi, temprature, co2, battery})

  return (
    <LocationTreeItem
      key={location.id}
      locationId={location.id}
      locationName={location.name}
      lastUpdate={lastUpdate}
      clientSum={clientSum}
      allSensors={allSensors}
      activeSensors={activeSensors}
      tempratureBounds={tempratureBounds}
      co2Bounds={co2Bounds}
      // batteryBounds={batteryBounds}
      setLocationId={setLocationId}
      headerItem={headerItem}
      useFilters={useFilters}
      userIsAdmin={userIsAdmin}
    />
  );
}

export const LocationsOutput = ({locations, useFilters, id, setLocationId, sensors, clientsWiFi, temprature, co2, battery}) => {
  const [filterValue] = useFilters;
  const filterLowerCase = filterValue.toLowerCase();
  const filteredLocations = locations.filter(({location, name, id : filterId}) => {
    if(filterValue !== EMPTY_STRING) return (
      name.toLowerCase().indexOf(filterLowerCase) > -1 || 
      filterId.toLowerCase().indexOf(filterLowerCase) > -1
    )
    return location === id
  })
  // Showing active locaitons first
  filteredLocations.sort((locationA, locationB) => {
    const {lastUpdate: A} = getLocationTreeProps(locationA, {sensors, clientsWiFi, temprature, co2, battery})
    const {lastUpdate: B} = getLocationTreeProps(locationB, {sensors, clientsWiFi, temprature, co2, battery})

    return B - A
  })
  
  
  return filteredLocations.map(location => (
    <Location 
      key={location.id}
      location={location}
      clientsWiFi={clientsWiFi} 
      sensors={sensors} 
      temprature={temprature} 
      co2={co2}
      battery={battery}
      useFilters={useFilters}
      setLocationId={setLocationId}
    />
  ))
}

export const SensorsOutput = ({sensors, useFilters, locationDetail, clientsWiFi, id, co2, battery, temprature, useSensors}) => {
  const styles = useStyles();
  const [filterValue] = useFilters;
  const filterLowerCase = filterValue.toLowerCase();
  const filteredSensors = sensors.filter(({location, name, id: filterId}) => {
    if(filterValue !== EMPTY_STRING) return (
      name.toLowerCase().indexOf(filterLowerCase) > -1 || 
      filterId.toLowerCase().indexOf(filterLowerCase) > -1
    )
    return location === id
  })

  filteredSensors.sort((sensorA, sensorB) => {
    const {lastUpdate: A} = getSensorProps(sensorA, {clientsWiFi, temprature, co2, battery})
    const {lastUpdate: B} = getSensorProps(sensorB, {clientsWiFi, temprature, co2, battery})

    return B - A
  })
  
  return (
    <>
      {filteredSensors.length > 0 && locationDetail && 
        <div className={styles.header}>
          <Typography variant="caption">
            Sensors in {locationDetail.name}
          </Typography>
          <Divider className={styles.margin} />
        </div>
      }
      {filteredSensors.map(sensor => {
        const {
          latestClientsWiFi,
          sensorCO2,
          sensorBattery,
          sensorTemprature,
          lastUpdate,
        } = getSensorProps(sensor, {clientsWiFi, temprature, co2, battery})
        

          return (
            <SensorTreeItem
              useSensors={useSensors}
              key={sensor.id}
              sensor={sensor}
              clients={latestClientsWiFi ? latestClientsWiFi : null}
              co2={sensorCO2 ? sensorCO2 : null}
              battery={sensorBattery ? sensorBattery : null}
              temperature={sensorTemprature ? sensorTemprature : null}
              lastUpdate={lastUpdate}
            />
          )
        })
      }
    </>
  )
}

const LocationHeader = ({locationId, useFilters, locations, setLocationId, userIsAdmin, details:{sensors, clientsWiFi, temprature, co2, battery}}) => {
  const styles = useStyles()
  const [filters] = useFilters;
  const currentLocation = locationId === "#" ? null : locations.find(({id}) => id === locationId)

  return(
    <div className={styles.header}>
      {filters === EMPTY_STRING ? 
      <>
        <LocationBreadcrumbs 
          locations={locations}
          locationId={locationId}
          handleClick={setLocationId}
          maxItems={3}
          className={styles.breadcrumb}
        />
        {currentLocation && 
        <div className={styles.locationBar}>
          <Location
            location={currentLocation}
            clientsWiFi={clientsWiFi} 
            sensors={sensors} 
            temprature={temprature} 
            co2={co2}
            battery={battery}
            setLocationId={setLocationId}
            headerItem={true}
            useFilters={useFilters}
            userIsAdmin={userIsAdmin}
          />
        </div>
        }
        <Divider className={styles.margin} />
      </>
      :
      <></>
      }
    </div>
  )
}

const TreePane = (props) => {
  const {
    id: locationId,
    setLocationId,
    useFilters,
    useSensors,
    locations,
    sensors,
    clientsWiFi,
    temprature,
    co2,
    battery,
    userIsAdmin,
  } = props;

  const locationDetail = locations.find(({id}) => id === locationId)
  return (
    <>  
      <LocationHeader 
        locations={locations}
        locationId={locationId}
        setLocationId={setLocationId}
        useFilters={useFilters}
        userIsAdmin={userIsAdmin}
        details={{
          clientsWiFi,
          sensors,
          temprature,
          co2,
          battery
        }}
      />
      <LocationsOutput 
        id={locationId} 
        locations={locations} 
        useFilters={useFilters}
        clientsWiFi={clientsWiFi} 
        sensors={sensors} 
        temprature={temprature} 
        co2={co2}
        battery={battery}
        setLocationId={setLocationId}
      />
      <SensorsOutput 
        id={locationId} 
        locationDetail={locationDetail}
        useFilters={useFilters}
        sensors={sensors} 
        clientsWiFi={clientsWiFi}  
        temprature={temprature} 
        co2={co2}
        battery={battery}
        useSensors={useSensors}
      />
    </>
  )
};

export default TreePane
