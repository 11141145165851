// Base React
import React, { useState, useEffect, lazy } from "react";
import Moment from "react-moment";

// Styles 
import { makeStyles } from '@material-ui/core/styles';

// Material UI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography"
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

// Helpers
import {updateByIdShadow} from '../../Helpers/APIHelper'
import {SENSORS} from '../../Helpers/constants'

// Components

const RSSILookup = lazy(() => import('../../Components/RSSILookup'))

// Styles

const APListStyles = makeStyles(theme => ({

    expansionDetail: {
        display: 'grid',
        gridTemplateColumns: '75px 1fr 2fr 1fr 1fr',
        width: '100%',
        padding: '8px 16px 16px',
        "& > *": {
            paddingLeft: 0,
          },
    }, 
    shadowGridHeader: {
        height: '48px',
        display: 'grid',
        gridTemplateColumns: '75px 2fr 2fr 1fr 36px',
        padding: '0 16px 0 16px',
        "& > *": {
            paddingLeft: 0,
            paddingRight: 0,
   
       
          "& > *": {
            width: '100%',
   
          },   
        },
    }, 
    expansionSummary: {
        padding: '0 16px 0 16px',
    },
    shadowSummary: {
        display: 'grid',
        gridTemplateColumns: '75px 2fr 2fr 1fr',
        width: '100%',
        "& > *": {
            alignSelf: 'center',
          },
    },      
    shadowHeader: {
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        borderBottomColor: 'rgba(0, 0, 0, 0.12)',
        borderBottom: '1px solid',
    },
    marginBottom: {
        marginBottom: theme.spacing(2),
    },
    RSSI: {
        display:'flex',
        "& > *": {
            paddingRight: theme.spacing(1),
          },
    }
  }));

const SensorShadowAPList = ({shadow, sensor}) => {

    const classes = APListStyles();

    const whiteList= shadow.state.reported.config?.APs_v1 || {};
    const visible = shadow.state.reported.visible || {};

    // Zlib

    const zlib = require('zlib');

    var zlibAPs = visible.APs_v1.baseAPs_v1

    // Info on inflating zlib without callback
    // https://stackoverflow.com/a/39800991

    var inflatedAPs = JSON.parse(zlib.inflateSync(new Buffer(zlibAPs, 'base64')).toString());

    //console.log(inflatedAPs)

    // Expansion Panel 

    const [expanded, setExpanded] = React.useState(false);

    const handleExpansionPanelChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    //console.log(whiteList)

    


    return (

        <div className={classes.marginBottom}>

            <div className={classes.shadowHeader}>
            <Typography variant="body1" >
            <b>Shadow Access Points </b>
            </Typography>

            {/* Shadow list update time */}

            <Typography variant="caption">
            <span>Updated <Moment fromNow>{new Date(visible.APs_v1.updated * 1000)}</Moment></span>
            </Typography>
            </div>

            <List className={classes.shadowGridHeader}>

            <ListItem><Typography variant="body2" color="textSecondary">Listen</Typography></ListItem>
            <ListItem><Typography variant="body2" color="textSecondary">Address</Typography></ListItem>
            <ListItem><Typography variant="body2" color="textSecondary">Signal Strength Indicator</Typography></ListItem>
            <ListItem><Typography align="center" variant="body2" color="textSecondary">SSIDs</Typography></ListItem>
            
            </List>

            {Object.entries(inflatedAPs).map(([k1, v1],i1) => {
            
                return (
                
                <ExpansionPanel 
                    expanded={expanded === 'panel'+i1} 
                    onChange={handleExpansionPanelChange('panel'+i1)}
                   
                >

                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header" 
                    className={classes.expansionSummary}
                >

                <div className={classes.shadowSummary}>

                {/* Listen Checkbox */}

                <APCheckBox ap={v1} addr={v1.addr} whiteList={whiteList} sensor={sensor.id} />

                <Typography>{v1.addr}</Typography>                

                { v1.RSSIs.length > 1 ? 
                
                <>
         
                <div className={classes.RSSI}>
                    <Typography>2.4GHz</Typography>
                    <RSSILookup RSSI={v1.RSSIs[0]} />    
                    <Typography>5GHz</Typography>
                    <RSSILookup RSSI={v1.RSSIs[1]} />      
                </div>
                
                </>

                :

                <>
                <RSSILookup RSSI={v1.RSSI} />
                </>

                }

                <Typography align="center">{v1.APs.length}</Typography>

                {/* <p><pre>{ JSON.stringify(v1) }</pre></p> */}
          
                </div>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails className={classes.expansionDetail}>

                {/* Setup Headers */}

                <Typography variant="body2" color="textSecondary">Listen</Typography>
                <Typography variant="body2" color="textSecondary">Address</Typography>
                <Typography variant="body2" color="textSecondary">SSID</Typography>
                <Typography variant="body2" color="textSecondary">Channel</Typography>
                <Typography variant="body2" color="textSecondary">Last Seen</Typography>
                
                {/* Loop Through AP List */}

                {/* Example List
                SSID: "Amazon ConfigureMe"
                channel: 1
                last_seen: 1588367512.603496
                listen: 0 */}

                {Object.entries(v1.APs).map(([k1,v1]) => Object.entries(v1).map(([k2,v2]) => {
                    
                    return (
                    <>
                    {/* <p><pre>{ JSON.stringify(v2) }</pre></p> */}
                    <APCheckBox ap={v2} addr={k2} whiteList={whiteList} sensor={sensor.id} />

                    <Typography>{k2}</Typography>
                    <Typography>{v2.SSID}</Typography>
                    <Typography>{v2.channel}</Typography>
                    <Typography><Moment fromNow>{new Date(v2.last_seen * 1000)}</Moment></Typography>
                    
                    </>
                    )
                            
                }))}

                </ExpansionPanelDetails>                        
                
                </ExpansionPanel>

                )

            })}
        {/* <p>WhiteList</p>
        { JSON.stringify(whiteList.addr_whitelist)} */}

        </div>
      
    )
}

function APCheckBox(props) {

    const classes = APListStyles();

    const {ap, whiteList, addr, sensor} = props;
    const addr_whitelist = whiteList.addr_whitelist || [];

    const listen = ap.listen
    //console.log(compareMAC(addr, whiteList.addr_whitelist).overallMatchBoolean)
    const inWhiteList = compareMAC(addr, addr_whitelist).overallMatchBoolean
   
    // Store Checked
    const [checked, setChecked] = React.useState(false);

    // Dialog Box

    const [open, setOpen] = React.useState(false);

    const handleDialogClickOpen = () => {
        setOpen(true);
        
    };

    const handleDialogCancel = () => {
        //console.log("Cancel")
        setOpen(false);
    };

    const handleDialogConfirm = () => {
        //console.log("Confirm")
        onDialogConfirm()
        setOpen(false);

    };

    // Checkbox

    const handleCheckBoxChange = (event) => {
        setChecked(event.target.checked)
        handleDialogClickOpen()
    };

    const onDialogConfirm = () => {
        if (checked) {
            console.log("Checkbox not ticked, add")
            var newAddrWhiteList = addr_whitelist.concat(addr)
            //console.log(newAddrWhiteList)

        } else {

            if (inWhiteList) {
                console.log("Remove from whitelist")
                var newAddrWhiteList = addr_whitelist

                // Get index of addr to be removed
                var index = compareMAC(addr, newAddrWhiteList).index

                // Remove it
                if (index > -1) {
                    newAddrWhiteList.splice(index, 1);
                }

            } else if (! inWhiteList) {
                console.log("Not in whitelist but listening, add to whitelist")
                var newAddrWhiteList = addr_whitelist.concat(addr)
            }
        
        }

        var PUTbody = {
            "state": {
              "desired": {
                "config": {
                  "APs_v1": {
                      "addr_whitelist": newAddrWhiteList,
                    
                  }
                }
              }
            }
          }
        console.log(PUTbody)

        updateByIdShadow(SENSORS, sensor, PUTbody)  
             .then(response => {
             console.log(response)
             }) 
    }

    // Set Colors  

    if (listen && inWhiteList) {
        //console.log("Listen and whitelist")
        var color = "secondary"
    } else if (listen && ! inWhiteList) {
        //console.log("Listen but no whitelist")
        var color = "light"
    } else if (! listen && inWhiteList) {
        //console.log("Whitelist but not listen")
        var color = "primary"
    } else {
        var color = "dark"
    }  

    //console.log("Address: "+addr+" Listen: "+listen+" In Whitelist: "+inWhiteList)

    return (
        <div className={classes.checkBox}>
                
            <Checkbox
                checked={ap.listen ||  compareMAC(addr, addr_whitelist).overallMatchBoolean}
                color={color}
                onChange={handleCheckBoxChange}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />

            <APCheckBoxDialog addr={addr} open={open} onSubmit={handleDialogConfirm} onCancel={handleDialogCancel} />   

        </div>
    )

}

function APCheckBoxDialog(props) {

    const {onCancel, onSubmit, open, addr } = props;

    const handleCancel = (event) => {
        event.stopPropagation()
        onCancel();
    }
  
    const handleSubmit = (event) => {
        event.stopPropagation()
        onSubmit();
    }

    return (
      <Dialog onClose={handleCancel} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
        
            <DialogContent>
            <DialogContentText>
                Please confirm you want to change status of {addr}.
            </DialogContentText>
            
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Confirm
                </Button>
            </DialogActions>
             
        </Dialog>
    );
  }

function compareMAC(addr, whiteListAddr) {

    //console.log('comparing '+addr+' to '+whiteListAddr)
    if (whiteListAddr.length>0) {
        //console.log("There is a whitelist")
        var i,x,y, match, overallMatch=0;
        for (i = 0; i < whiteListAddr.length; i++) { 
            var oldOverallMatch = overallMatch
            y = whiteListAddr[i]
            //console.log("Comparing: "+addr+ "to: "+y)
            match = 1
            for (x = 0; x < y.length; x++) {
                //console.log(y[x])
                if (y[x]!=".") {
                    if (y[x] != addr[x]) {
                        //console.log('Found a mismatch, match false and jump to next item in whiteList')
                        match = 0
                    } 
                }            
            }
            //console.log(i)
            //console.log('Finished item in whiteList, add to overall match')
            overallMatch += match
            //console.log("overallMatch: "+overallMatch+" and index: "+i)
            if (oldOverallMatch == 0 && overallMatch == 1) {
                //console.log('Overall Match goes high with index: '+i)
                var index = i     
            }
      }

    if (overallMatch > 0) {
        var overallMatchBoolean = true
    } else {
        var overallMatchBoolean = false
    }

    //console.log("Returning: "+overallMatch)
    
    } else {
        //console.log("There isn't a whitelist")
        overallMatchBoolean = false
        index = 0 
    }
    return {overallMatchBoolean, index}

}

export default SensorShadowAPList
