import React, { useState, useEffect, lazy, Suspense } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

import { Header, Loading } from "../";
import { USER_ADMIN_TYPES } from "../../Helpers/constants";
import { fetchUserDetails } from "../../Helpers/APIHelper";
// Cognito Auth things that were helpful
//  - https://aws-amplify.github.io/docs/js/authentication
//  - (above) see manual setup and "Using Auth Components in React"
//  - https://stackoverflow.com/questions/55353013/using-aws-cognito-amplify-just-as-an-oidc-service
//  - helped https://stackoverflow.com/questions/48777321/aws-amplify-authentication-how-to-access-tokens-on-successful-auth-signin
//  - https://stackoverflow.com/questions/48777321/aws-amplify-authentication-how-to-access-tokens-on-successful-auth-signin
//  - https://www.valentinog.com/blog/await-react/ (since it is async)
import { Auth, Hub } from "aws-amplify";
// New UI components - See https://docs.amplify.aws/ui/q/framework/react
import { AmplifyAuthenticator, AmplifySignUp } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

Auth.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

    // REQUIRED - Amazon Cognito Region
    region: "eu-west-2",

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    //identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "eu-west-2_syosev8UK",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "3i63qhk5l3kii5i4p799qhalqo", // portal_lf-react

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: "lightfiv2.auth.eu-west-2.amazoncognito.com",
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: "http://localhost:3000/",
      redirectSignOut: "http://localhost:3000/",
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

const Dashboard = lazy(() => import("../../Views/Dashboard"));
const SensorConfig = lazy(() => import("../../Views/SensorConfig"));
const LocationConfig = lazy(() => import("../../Views/LocationConfig"));
const LocNavTree = lazy(() => import("../../Views/LocNavTree"));
const DataMap = lazy(() => import("../../Views/DataMap"));

const AppRoutes = ({ user, handleLogOut }) => {
  const [treeOpen, toggleTree] = useState(false);

  const [userIsAdmin, setUserIsAdmin] = useState(false);

  React.useEffect(() => {
    if (!!user) {
      fetchUserDetails().then((userDetails) => {
        if (USER_ADMIN_TYPES.includes(userDetails.access_level)) {
          setUserIsAdmin(true);
        }
      });
    }
  }, [user]);

  return (
    <Router>
      <Header
        handleLogOut={handleLogOut}
        user={user}
        useTree={[treeOpen, toggleTree]}
      />
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/datamap">
            <DataMap />
          </Route>
          {/* <Route path="/locations/:locationId">
            <LocationConfig />
          </Route> */}
          <Route path="/sensors/:sensorId">
            <SensorConfig />
          </Route>
          <Route path="/sensordata">
            <LocNavTree
              userIsAdmin={userIsAdmin}
              treeState={[treeOpen, toggleTree]}
            />
          </Route>
          <Route path="/">
            <Dashboard />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

const App = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  const handleLogOut = () => Auth.signOut();

  return authState === AuthState.SignedIn && user ? (
    <AppRoutes user={user} handleLogOut={handleLogOut} />
  ) : (
    <AmplifyAuthenticator usernameAlias="email">
      <AmplifySignUp
        slot="sign-up"
        usernameAlias="email"
        formFields={[
          {
            type: "email",
            required: true,
          },
          {
            type: "password",
            required: true,
          },
          {
            type: "phone_number",
            required: false,
          },
        ]}
      />
    </AmplifyAuthenticator>
  );
};

export default App;
