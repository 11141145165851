import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import { sensorDataDictionary, dataColours, dataBounds } from '../../Helpers/constants';
import { faUser } from '@fortawesome/free-solid-svg-icons';

export const CustomMarker = ({sensor}) => {
  const styles = makeStyles(theme => ({
    marker: {
      background: theme.palette.grey[100],
      padding: theme.spacing(.5),
      width: theme.spacing(10),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[5],
      opacity: .9,
      transform: 'translateY(-100%) translateX(-50%)',
      transition: theme.transitions.create('all', {duration: theme.transitions.duration.standard, easing: theme.transitions.easing.easeInOut}),
      position: 'relative',
      zIndex: 1,
      "&:hover": {
        zIndex: 3,
        boxShadow: theme.shadows[10],
        opacity: 1,
      }
    },
    arrow : {
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      margin: 'auto',
      width: theme.spacing(1),
      height: theme.spacing(1),
      transform: 'rotate(45deg) translateY(-70%)',
      background: theme.palette.grey[100],
    },
    text: {
      display: 'flex',
      alignItems: 'center',
    },
    marginLeft: {
      marginLeft : theme.spacing(1),
    },
    metric: {
      opacity: .5
    }
  }))()

  const { value, varName } = sensor;
  const {icon, metric} = sensorDataDictionary[varName] || {icon: null, metric: null}
  return (
    <div className={styles.marker}>
      <span className={styles.arrow}></span>
      <Typography fontSize="small" variant="body1" className={styles.text}>
        {icon && <FontAwesomeIcon icon={icon} />}
        <span className={styles.marginLeft}>{value}</span>
        {metric && <small className={styles.metric}>{metric}</small>}
      </Typography>
    </div>
  )
}

export const ThermalMarker = () => {
  const styles = makeStyles(theme => ({
    marker: {
      background: theme.palette.grey[100],
      padding: theme.spacing(.5),
      width: theme.spacing(5),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[5],
      opacity: .9,
      transform: 'translateY(-100%) translateX(-50%)',
      transition: theme.transitions.create('all', {duration: theme.transitions.duration.standard, easing: theme.transitions.easing.easeInOut}),
      position: 'relative',
      zIndex: 1,
      "&:hover": {
        zIndex: 3,
        boxShadow: theme.shadows[10],
        opacity: 1,
      }
    },
    arrow : {
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      margin: 'auto',
      width: theme.spacing(1),
      height: theme.spacing(1),
      transform: 'rotate(45deg) translateY(-70%)',
      background: theme.palette.grey[100],
    },
    text: {
      display: 'flex',
      alignItems: 'center',
    },
  }))()
  return (
    <div className={styles.marker}>
      <span className={styles.arrow}></span>
      <Typography fontSize="small" variant="body1" className={styles.text}>
        <FontAwesomeIcon icon={faUser} />
      </Typography>
    </div>
  )
}

export const CircleColourMarker = ({sensor}) => {
  const { value, varName } = sensor;

  const styles = makeStyles(theme => {
    let colour = theme.palette.grey[100];

    if (varName === 'temperatureC') {
      if (value < dataBounds.degC.cold){
        colour = dataColours.blue7;
      } else if (value > dataBounds.degC.hot) {
        colour = dataColours.red7;
      } else {
        colour = dataColours.green7;
      }
    } else if (varName === 'CO2ppm') {
      if (value > dataBounds.CO2.high){
        colour = dataColours.red7;
      } else if (value > dataBounds.CO2.low) {
        colour = dataColours.orange7;
      } else {
        colour = dataColours.green7;
      }
    } else {
      colour = theme.palette.grey[100];
    }

    return ({
    marker: {
      background: colour,
      padding: theme.spacing(.5),
      width: theme.spacing(10),
      height: theme.spacing(10),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      borderRadius:'50%',
      boxShadow: theme.shadows[5],
      opacity: .9,
      transform: 'translateY(-50%) translateX(-50%)',
      transition: theme.transitions.create('all', {duration: theme.transitions.duration.standard, easing: theme.transitions.easing.easeInOut}),
      position: 'relative',
      zIndex: 1,
      "&:hover": {
        zIndex: 3,
        boxShadow: theme.shadows[10],
        opacity: 1,
      }
    },
    text: {
      display: 'flex',
      alignItems: 'center',
    },
    marginLeft: {
      marginLeft : theme.spacing(1),
    },
    metric: {
      opacity: 1
    }
  })})()

  const {icon, metric} = sensorDataDictionary[varName] || {icon: null, metric: null}
  return (
    <div className={styles.marker}>
      <Typography fontSize="small" variant="body1" className={styles.text}>
        {icon && <FontAwesomeIcon icon={icon} />}
        <span className={styles.marginLeft}>{value}</span>
        {metric && <small className={styles.metric}>{metric}</small>}
      </Typography>
    </div>
  )
}
