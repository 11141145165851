import React, {useState, lazy, Suspense} from 'react'
// import Plot from "react-plotly.js";
import {Loading} from '../'
import {sensorDataDictionary} from '../../Helpers/constants'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalendar} from '@fortawesome/free-solid-svg-icons'
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import NextIcon from "@material-ui/icons/NavigateNext"
import FirstPageIcon from "@material-ui/icons/FirstPage"
import LastPageIcon from "@material-ui/icons/LastPage"
import PrevIcon from "@material-ui/icons/NavigateBefore"

import moment from 'moment';
import './date-range-picker.css'

const DateRangePicker = lazy(() => import('@wojtekmaj/react-daterange-picker'));
const PlotContainer = lazy(() => import('../PlotContainer'))
const SensorLinks = lazy(() => import('../SensorLinks'))

const useStyles = makeStyles(theme => ({
  divider: {
    marginLeft: theme.spacing(2),
  },
  sensorCard: {
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0),
    }
  },
  rootMargin: {
    marginTop: theme.spacing(2)
  },
  wrapper: {
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    }
  },
  toggleButtonsHolder: {
    display: 'inline-grid',
    alignItems: 'center',
    fontSize: '.8em',
    gridColumnGap: theme.spacing(1),
    gridTemplateColumns: 'auto 1fr',
  },
  toggleButton: {
    height: theme.spacing(4),
  },
  topNav: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[200],
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridRowGap: theme.spacing(1),
      padding: theme.spacing(1),
    }
  },
  innerIcon: {
    padding: theme.spacing(.5),
  },
  chip: {
    marginRight: theme.spacing(1),
  },
}))

const DateRange = ({range, action}) => {
  // const classes = useStyles()
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState("Pick Date Range");


  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateRange = (range) => {
    handleClose();
    setLabel(`${moment(range[0]).format("MMM Do YY")} - ${moment(range[1]).format("MMM Do YY")}`);
    return action(range)
  }

  return (
    <div>
      <Button onClick={handleClick} >
        <Typography variant="caption">
          {label}
        </Typography>
      </Button>
      <Suspense fallback={<></>}>
        <DateRangePicker
          maxDate={new Date()}
          value={range}
          isOpen={open}        
          prevLabel={<PrevIcon />}
          prev2Label={<FirstPageIcon />}
          nextLabel={<NextIcon />}
          next2Label={<LastPageIcon />}
          onCalendarClose={handleClose}
          onChange={handleDateRange}
        />
      </Suspense>
    </div>
  );
}

// Show a plot next to the tree with a selected data
const PlotPane = (props) => {
  const classes = useStyles();
  const { sensors, useSensors } = props;
  
  const [selectedSensors, setSelectedSensors] = useSensors

  const [dateRange, setDateRange] = useState(null)

  const [varNames, updateVarNames] = useState(["clientsWiFi"]);

  const handleActiveVars = (newVar) => {
    if(varNames.includes(newVar)) return handleDeleteVar(newVar)

    if(varNames.length < 2) return updateVarNames([...varNames, newVar])

    return updateVarNames([varNames[varNames.length - 1], newVar])
  }

  const handleDeleteVar = (varN) => {
    updateVarNames(varNames.filter(item => item !== varN))
  }

  const clearAll = () => {
    setSelectedSensors([])
  }

  return (
    <div className={classes.wrapper}>
      <div>
        <Grid container alignItems="center" justify="space-between">
          <Typography variant="body1">{
            selectedSensors.length > 0 ? "Selected items" : "Please select a sensor from the list" }
          </Typography>
          {selectedSensors.length > 0 ? 
            <Button onClick={clearAll}>Clear All</Button>
            : <></>
          }
        </Grid>
        <Paper className={classes.sensorCard}>
          <Suspense fallback={<Loading />}>
            {selectedSensors.map(
              (item, index) => (
                <div key={item}>
                  <SensorLinks useSensors={useSensors} item={item} activeVars={varNames} handleActiveVars={handleActiveVars} />
                  {index !== selectedSensors.length - 1 ? <Divider className={classes.divider} /> : <></>}
                </div>
              )
            )}
          </Suspense>
        </Paper>
      </div>


      <Paper className={classes.rootMargin}>
        <div className={classes.topNav}>
          <div>
            {varNames.length > 0 && varNames.map(
              (varN, index) => {
                const {icon, title} = sensorDataDictionary[varN] || {icon: null, title: varN}
                return(
                  <Chip
                    key={index}
                    className={classes.chip}
                    icon={icon ? <FontAwesomeIcon className={classes.innerIcon} icon={icon} /> : null}
                    label={title}
                    onDelete={() => handleDeleteVar(varN)}
                  />
                )
              }
            )}
          </div>

          <div className={classes.toggleButtonsHolder}>
            <FontAwesomeIcon icon={faCalendar} className={classes.chip} />
            <DateRange action={setDateRange} range={dateRange} />
          </div>
        </div>
        <Suspense fallback={<Loading />}>
          <PlotContainer
            sensors={sensors}
            varNames={varNames}
            dateRange={dateRange}
            selectedSensors={selectedSensors}
          />
        </Suspense>
      </Paper>
    </div>
  );
}

export default PlotPane
