// To get data from the API we need to use the user access token
// To get the access token we load the currentSession (async)
// We can use a wrapper on fetch to avoid duplicate code
// https://stackoverflow.com/questions/54936559/using-async-await-in-react-component

import {Auth} from 'aws-amplify';
import {RANGES, DEFAULT_RANGE, SENSORS} from './constants'
import {parseParams, convertDateRange, formatISODate} from './functions'
export const API_URL = 'https://apiv2.lightfi.io'; //Use production API
//export const API_URL = 'http://localhost:8000';  //Use development API running locally

// This is async because currentSession is,
//  all places that use this therefore also need to be async
export async function getAccessToken() {
	const res = await Auth.currentSession();
	let accessToken = res.getAccessToken();
	let jwt = accessToken.getJwtToken();

	return jwt;
}

export async function fetchLFApi(route) {
	let jwt = await getAccessToken();
	console.log(`fetching ${API_URL}${route}`)
	return fetch(`${API_URL}${route}`, {
		headers: {
			Accept: "application/json, text/plain, */*",
			// "Content-Type": "application/json",
			Authorization: "Bearer " + jwt
		}
	})
}

export async function putLFApi(route, body) {
	let jwt = await getAccessToken();
	console.log(`putting ${API_URL}${route} ${body}`)
	let response = await fetch(
		API_URL + route, {
			headers: {
				Accept: "application/json, text/plain, */*",
				// "Content-Type": "application/json",
				Authorization: "Bearer " + jwt
			},
			method: 'PUT',
			body: JSON.stringify(body)
		}
	)
	return response
}

export async function postLFApi(route, body) {
	let jwt = await getAccessToken();
	console.log(`posting ${API_URL}${route} ${body}`)
	let response = await fetch(
		API_URL + route, {
			headers: {
				Accept: "application/json, text/plain, */*",
				"Content-Type": "application/json",
				Authorization: "Bearer " + jwt
			},
			method: 'POST',
			body: JSON.stringify(body)
		}
	)
	return response
}

// Data Specific Calls

export const fetchUserDetails = async () => {
	const route = '/users/me'
	
	const response = await fetchLFApi(route)
		.then(res => res.json())
	return response;
}

export const fetchAllByVarname = async (type, varName, params = null, range = RANGES.LATEST, date = null, locId = null) => {
	const encodedLocId = encodeURIComponent(locId)
	let route = `/${type}${locId ? `/${encodedLocId}/` : '/'}all`
	switch (range) {
		case RANGES.LATEST:
			route = `${route}/${varName}/${RANGES.LATEST}?`
			break;
		case RANGES.DAILY:
			route = `${route}/${varName}/${RANGES.DAILY}/${date}?`
			break;
		default: break;
	}

	if(params) {
		route += parseParams(params)
	}

	const response = await fetchLFApi(route)
		.then(res => res.json())
		.catch(err => ({
			data: [{ id: 0, name: "None" }]
		}))

	return response;
}

export const fetchAll = async (type, params) => {
	const route = `/${type}/all?${parseParams(params)}`
	
	const response = await fetchLFApi(route)
		.then(res => res.json())
		.catch(err => ({
			[type]: [{ id: 0, name: "None" }]
		}))

	return response;
}

export const fetchById = async (type, id, params = null, defaultReturn = {}, needSlash = false) => {
	let route = `/${type}/${encodeURIComponent(id)}${params ? `?${parseParams(params)}` :  (needSlash ? '/' : '')}`;
	const response = await fetchLFApi(route)
		.then(res => {
			if(res.status === 200) return res.json() 
			return defaultReturn
		})
		.catch((err) => err)
	return response;
}

// Get shadow by ID

export const fetchByIdShadow = async (type, id, params={}) => {
	let route = `/${type}/${encodeURIComponent(id)}/shadow`;
	route += parseParams(params)
	const response = await fetchLFApi(route)
		.then(res => {
			if(res.status === 200) return res.json() 
		})
		.catch(() => null)
	return response;
}

// Update shadow by ID
/// PUT sensors/{sensor_id}/shadow

export const updateByIdShadow = async (type, id, body,) => {
	let route = `/${type}/${encodeURIComponent(id)}/shadow`;
	const response = await putLFApi(route, body)
		.then(res => {
			if(res.status === 200) return res.json() 
		})
		.catch(() => null)
	return response;
}

// Create new sensor by ID
// POST /sensors/{sensor_id}

export const postById = async (type, id, body, params={}) => {
	let route = `/${type}/${encodeURIComponent(id)}`; 
	route += parseParams(params)
	const response = await postLFApi(route, body)
		.then(res => {
			if(res.status === 200) return res.json() 
		})
		.catch(() => null)
	return response;
}


export const updateById = async (type, id, body) => {
	const route = `/${type}/${encodeURIComponent(id)}`;
	const response = await putLFApi(route, body)
		.then(res => {
			if(res.status === 200) return res.json() 
		})
		.catch(err => err)
	return response;
}

export const getFloorPlan = async (locId) => {
	const route = `/locations/${encodeURIComponent(locId)}/floorplan`
	const response = await fetchLFApi(route)
		.then(res => {
			if(res.status === 200) return res.json()
			return null;
		})
		.catch(err => err)
	return response;
}

export const fetchAllSensorsInLocation = async (locId) => {
	const route = `/locations/${encodeURIComponent(locId)}/all/sensors`
	const response = await fetchLFApi(route)
		.then(res => {
			if(res.status === 200) return res.json()
			return null;
		})
		.catch(err => err)
	return response;
}

export const getSensorHistory = async (id, varName, tstart = null, tend = null) => {
  // API route takes tstart/tend time in seconds
	let route = `/sensors/${id}/${varName}/history`;

	if(tstart) {
		route += `?tstart=${tstart}${tend ? `&tend=${tend}` : ''}`
	}

	const response = await fetchLFApi(route)
		.then(res => res.json())
		.catch(() => null)
	return response;
}

export const fetchDailyById = async (type, id, varName, range = DEFAULT_RANGE) => {
	const {tstart, tend} = convertDateRange(range);
	console.log(range, tstart, tend);
	const dStart = formatISODate(new Date(tstart * 1000))
	const dEnd = formatISODate(new Date(tend * 1000))
	let route = `/${type}/${encodeURIComponent(id)}/${varName}/daily?d_start=${dStart}&d_end=${dEnd}`;
	const response = await fetchLFApi(route)
		.then(res => {
			if(res.status === 200) return res.json() 
			return null
		})
		.catch((err) => err)
	return response;
}

const DEFAULT_OPTIONS = {
	daterange: null,//[tstart, tend],
	varName: null,
	returnDefault: null, // () => null,
	search: null,
	lookFor: '',
	corshotfix: false,
}

export const LOOKFOR = {
	FLOORPLAN: 'FLOORPLAN',
	LOCATION_VARNAME: 'LOCATION_VARNAME',
	ALL_LOCATIONS: 'ALL_LOCATIONS',
	ALL_LOCATION_VARNAME: 'ALL_LOCATION_VARNAME',
	LOCATION_SENSORS: 'LOCATION_SENSORS',
	SENSOR_VARNAME: 'SENSOR_VARNAME',
	SHADOW_SENSOR: 'SHADOW_SENSOR'
}

const LOOKFOR_CREATOR = {
	locationVarname: (varName, daterange) => {
		let route = `/${varName}`
			// if no daterange is provided return varName, request will fail.
		if(!daterange) return route;
		// if daterange is provided return daily
		const {tstart, tend} = convertDateRange(daterange);
		const dStart = formatISODate(new Date(tstart * 1000))
		const dEnd = formatISODate(new Date(tend * 1000))
		return route + `/daily?d_start=${dStart}&d_end=${dEnd}`
	},
	allLocationsVarName: (varName, daterange) => {
		let route = `/all/${varName}`
		// if no daterange is provided return latest
		if(!daterange) return route + '/latest';
		// if daterange is provided return daily
		const {tstart} = convertDateRange(daterange);
		const dateString = formatISODate(new Date(tstart * 1000))
		return route + `/daily/${dateString}`
	},
	sensorVarName: (varName, daterange) => {
		let route = `/${varName}`
		// If no daterange provided, return sensor history for varname
		if(!daterange) return route + '/history'
		// If daterange is singular, it's a daily by date
		if(!!daterange && !!daterange[0] && !daterange[1]) {
			const {tstart} = convertDateRange(daterange);
			const dateString = formatISODate(new Date(tstart * 1000))
			return route + `/daily/${dateString}`
		}
		if(!!daterange) {
			const {tstart, tend} = convertDateRange(daterange);
			const dStart = formatISODate(new Date(tstart * 1000))
			const dEnd = formatISODate(new Date(tend * 1000))
			return route + `/daily?d_start=${dStart}&d_end=${dEnd}`
		}
		return route;
	}
}

const lookingFor = (lookFor, varName, daterange) => {
	switch(lookFor) {
		case LOOKFOR.FLOORPLAN: 
			return '/floorplan'

		case LOOKFOR.ALL_LOCATIONS: 
			return '/all'

		case LOOKFOR.LOCATION_VARNAME: 
			return LOOKFOR_CREATOR.locationVarname(varName, daterange)

		case LOOKFOR.ALL_LOCATION_VARNAME: 
			return LOOKFOR_CREATOR.allLocationsVarName(varName, daterange)

		case LOOKFOR.LOCATION_SENSORS: 
			return '/all/sensors'

		case LOOKFOR.SHADOW_SENSOR: 
			return '/shadow'

		case LOOKFOR.SENSOR_VARNAME: 
			return LOOKFOR_CREATOR.sensorVarName(varName, daterange)

		default: return ''
	}
}

const lookingForAllSensors = (varName, daterange) => {
	if(!daterange && !varName) return ''

	if(!daterange && !!varName) return `/${varName}/latest`

	return LOOKFOR_CREATOR.sensorVarName(varName, [daterange[0], null])
}

const createRoute = (type, id, varName, search, lookFor, daterange, corshotfix) => {
	let route = `/${type}`
	// step 1: is Id present? what are you lookingfor?
	if(!!id) {
		route += `/${encodeURIComponent(id)}` + lookingFor(lookFor, varName, daterange)
	} 
	// no id, fetching all? no problem.
	if(!id) {
		route += '/all'
		if(type === SENSORS) {
			route += lookingForAllSensors(varName, daterange)
		}
	}
	// step2: corshotfix? 
	if(corshotfix) route += '/'
	// step 2: adding params is available
	if(!!search) route += '?' + parseParams(search)
	
	return route
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////
// THIS SINGLE NEW FUNCTION IS SUPPORTING ALL FETCH API CALLS. 
//
// HOW TO USE: 
// type: LOCATION | SENSORS (use from constants)
// id: in case of null, it will do /{locations|sensors}/all, 
// >> tip: if varname, and daterange is present, and type is sensor it 
// >> looks for /sensors/all/$varname/{latest | daily} depending on daterange.
// >> daterange: [tstart, tend], if [tend] is not present, it considers it as a date
//
// options: use makeOptions({...}) and add each variable you want to modify
// >> options.varname, is the varname
// >> options.daterange: [tstart] || [tstart, tend]
// >> options.search: {project: [all, location]} will be ?project=all&project=location
// >> options.lookFor: the purpose of the query, use LOOKFOR constant.
// >> options.returnDefault: the default amount you need request to return if nothing find.
// >> options.corshotfix: some queries will not work without '/' by the end,
//
// this way we don't need to create a new function for each api call, 
// but if it's hard to remember we can create wrappers using this function.
/////////////////////////////////////////////////////////////////////////////////////////////////////////

export const makeOptions = (options) => {
	return {
		...DEFAULT_OPTIONS,
		...options,
	}
}

export const fetchData = async (type, id = null, options = DEFAULT_OPTIONS)  => {
	const {daterange, varName, returnDefault, search, lookFor, corshotfix} = options;

	const route = createRoute(type, id, varName, search, lookFor, daterange, corshotfix);

	const response = await fetchLFApi(route)
		.then(res => {
			if(res.status === 200) return res.json() 
			return returnDefault
		})
		.catch((err) => err)

	return response;
}
