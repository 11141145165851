// Base React
import React, { useState, useEffect} from "react";
import Moment from "react-moment";

// Styles 
import { makeStyles } from '@material-ui/core/styles';

// Material UI
import Typography from "@material-ui/core/Typography"

// Components
import {SensorShadowAPList} from '../../Components'
import {SensorShadowSubSensorList} from '../../Components'
import {SensorShadowAPListOld} from '../../Components'

// Helpers
import {fetchByIdShadow} from '../../Helpers/APIHelper'
import {SENSORS} from '../../Helpers/constants'
//import { shadowDictionary } from "../../Helpers/constants";

const SensorShadow = ({sensor}) => {
  /* Things we do here:
   *  - Check the shadow exists in the right format (if not return null)
   *  - Check if we have old type shadow or new type
   *    - If new type use SensorShadowAPList & SensorShadowSubSensorList
   *    - If old type use SensorShadowAPListOld
   *    - else sensor may not have booted yet 
   */

  const [shadow, setShadow] = useState({});
  const [shadowRefresh, setShadowRefresh] = useState(0);
  const refreshShadow = () => setShadowRefresh(shadowRefresh+1);

  useEffect(() => {
    fetchByIdShadow(SENSORS, sensor.id)
      .then(response => {
        setShadow(response)
      })
  }, [sensor, shadowRefresh]);

  // Check if the shadow exists in with required sections
  // note: using "optional chaining" from ECMAScript 2020 and Babel > 7.8.0
  if (!shadow?.state?.reported) {
    return null
  }
  // Shadow exists
  // Check if the shadow is a new or old type
  if(shadow.state.reported.hasOwnProperty('visible')){
    //console.log('New Type');
    return (
      <>
        <SensorShadowSubSensorList
          sensor={sensor}
          shadow={shadow}
          refreshShadow={refreshShadow}
        />
        <SensorShadowAPList
          shadow={shadow}
          sensor={sensor}
          refreshShadow={refreshShadow}
        />
      </>
    )
  } else if(shadow.state.reported.hasOwnProperty('apList')) {
    //console.log('Old Type')
    return (
      <SensorShadowAPListOld 
        shadow={shadow.state.reported} 
      />
    )
  } else {
    return (
      <div>
        <Typography variant="h6">Sensor not yet reported visible APs/subsensors, try later?</Typography>
      </div>
    )
  }
}

export default SensorShadow
